import { type ShipmentStatus } from '~shared/types';

export type StatusMap = Record<
  ShipmentStatus,
  {
    statusString: string;
    mailStatusString?: string;
    pickupStatusString?: string;
    index: number;
    pickupIndex?: number;
    // present tense
    infoText?: string;
    mailInfoText?: string;
    pickupInfoText?: string;
    // future tense
    infoTextFuture?: string;
    mailInfoTextFuture?: string;
    pickupInfoTextFuture?: string;
    uspsInfoTextFuture?: string;
  }
>;

export const FINAL_INDEX = 3;
export const SHIPMENT_STATUS_MAP: StatusMap = {
  processing: {
    statusString: 'Processing',
    mailStatusString: 'Processing',
    pickupStatusString: 'Processing',
    index: 1,
    // present tense
    infoText: "We're processing your order",
    mailInfoText: "We're processing your order",
    pickupInfoText: "We're processing your order",
    // future tense
    infoTextFuture: "We'll prepare your order.",
    mailInfoTextFuture: "We'll prepare your order.",
    pickupInfoTextFuture: "You'll receive a notification when your order is ready.",
  },
  awaiting_packing: {
    statusString: 'Processing',
    mailStatusString: 'Processing',
    pickupStatusString: 'Processing',
    index: 1,
    // present tense
    infoText: "We're preparing your order.",
    mailInfoText: "We're preparing your order.",
    pickupInfoText: "We're preparing your order.",
    // future tense
    infoTextFuture: "We'll prepare your order.",
    mailInfoTextFuture: "We'll prepare your order.",
    pickupInfoTextFuture: "You'll receive a notification when your order is ready.",
  },
  packaged: {
    statusString: 'Processing',
    mailStatusString: 'Processing',
    pickupStatusString: 'Processing',
    index: 1,
    // present tense
    infoText: "We're preparing your order.",
    mailInfoText: "We're preparing your order.",
    pickupInfoText: "We're preparing your order.",
    // future tense
    infoTextFuture: "We'll prepare your order.",
    mailInfoTextFuture: "We'll prepare your order.",
    pickupInfoTextFuture: "You'll receive a notification when your order is ready.",
  },
  shipped: {
    statusString: 'On the way',
    mailStatusString: 'Shipped',
    pickupStatusString: 'Ready for pickup',
    index: 2,
    // present tense
    infoText: 'Your order is out for delivery!',
    mailInfoText: 'Your order has been shipped!',
    pickupInfoText: '',
    // future tense
    infoTextFuture: "We'll send a tracking link when your order has left our facility.",
    mailInfoTextFuture:
      "We'll send a tracking link when your order has shipped, typically the evening before your delivery.",
    pickupInfoTextFuture: "You'll receive a notification when your order is ready.",
    uspsInfoTextFuture:
      "We'll send a tracking link when your order has shipped, typically a few days before your delivery.",
  },
  delivered: {
    statusString: 'Delivered',
    mailStatusString: 'Delivered',
    pickupStatusString: 'Picked up',
    index: FINAL_INDEX,
    // present tense
    infoText: 'Your order was delivered!',
    mailInfoText: 'Your order was delivered!',
    pickupInfoText: 'Your order has been picked up!',
    // future tense - don't show anything
    infoTextFuture: '',
    mailInfoTextFuture: '',
    pickupInfoTextFuture: '',
  },
  returned: {
    statusString: 'Returned',
    mailStatusString: 'Returned',
    index: FINAL_INDEX,
    // present tense
    infoText: 'This order was returned.',
  },
  failed: {
    statusString: 'Failed',
    mailStatusString: 'Failed',
    index: FINAL_INDEX,
    // present tense
    infoText: 'Our courier was unable to leave your package',
    mailInfoText: 'We were unable to deliver your package',
  },
  redo: {
    statusString: 'Failed',
    mailStatusString: 'Failed',
    index: FINAL_INDEX,
    // present tense
    infoText: 'Our courier was unable to leave your package',
    mailInfoText: 'We were unable to deliver your package',
  },
  // "upcoming" is not a shipment status, it is a delivery status.
  // Remove this key from the map when we no longer default to the delivery
  // status if there is no shipment status in native.
  upcoming: {
    statusString: 'Scheduled',
    mailStatusString: 'Scheduled',
    pickupStatusString: 'Scheduled',
    index: 1,
    infoText: "We're processing your order.",
  },
  // "unscheduled" is not a shipment status, it is a delivery status.
  // Remove this key from the map when we no longer default to the delivery
  // status if there is no shipment status in native.
  unscheduled: {
    statusString: 'Prior Auth required',
    mailStatusString: 'Prior Auth required',
    index: 0,
    infoText:
      "Your insurance plan requires a Prior Authorization for this prescription. We've started the process by contacting your doctor for more information. Prior Authorizations take 5-7 business days on average. If you need this medication immediately, you may choose to pay without insurance to ensure you receive your medication on time.",
  },
  // "canceled" is not a shipment status, it is a delivery status.
  // Remove this key from the map when we no longer default to the delivery
  // status if there is no shipment status in native.
  canceled: {
    statusString: 'Order canceled',
    mailStatusString: 'Order canceled',
    index: FINAL_INDEX,
    infoText: 'This order was canceled.',
    mailInfoText: 'This order was canceled.',
  },
};

export const SHIPMENT_STATUS_MAP_V2: StatusMap = {
  ...SHIPMENT_STATUS_MAP,
  awaiting_packing: {
    ...SHIPMENT_STATUS_MAP.awaiting_packing,
    pickupStatusString: 'Ready for pickup',
    pickupIndex: 2,
    // present tense
    pickupInfoText: '',
    // future tense
    pickupInfoTextFuture: "You'll receive a notification when your order is ready.",
  },
  packaged: {
    ...SHIPMENT_STATUS_MAP.packaged,
    pickupStatusString: 'Ready for pickup',
    pickupIndex: 2,
    // present tense
    pickupInfoText: '',
    // future tense
    pickupInfoTextFuture: "You'll receive a notification when your order is ready.",
  },
};

// these should remain in the following order.
export const UPCOMING_STATUSES: ShipmentStatus[] = ['processing', 'packaged', 'shipped'];

export const FAILED_STATUSES = ['canceled', 'failed'];

export const SUCCESSFUL_STATUSES = ['delivered'];

export const TERMINAL_STATUSES = [...FAILED_STATUSES, ...SUCCESSFUL_STATUSES, 'returned'];

export const IN_PROGRESS_STATUSES = [...UPCOMING_STATUSES, 'awaiting_packing'];
