// @owners { team: patients-team }
import { Description, InlineAlert, MdSpacing } from '@alto/design-system';
import React, { useEffect, useState } from 'react';
import { getUpdatedEssentialQuantityIsBelowFreeDeliveryMinimum } from '~shared/features/pricing/selectors/getEssentialsOnlyOrderBelowMinimum';
import { getHasEssentialsOnlyShipmentFee } from '~shared/features/shipment-fees/selectors/getShipmentFeesFromShipment';
import { useSelectorShared } from '~shared/store';
import { useQueryEssentialsFeeConfigs } from '../queries/useQueryEssentialsFeeConfigs';

export type Props = {
  readonly essentialPrice: number;
  readonly initialQuantity: number;
  readonly quantity: number;
  readonly quantityChanged: boolean;
  readonly shipmentID: number | null | undefined;
};

export const UpdateWillAddEssentialsFeeWarning = ({
  essentialPrice,
  initialQuantity,
  quantity,
  quantityChanged,
  shipmentID,
}: Props) => {
  const [showMinimumWarning, setShowMinimumWarning] = useState(false);
  const shipmentHasEssentialsOnlyFee = useSelectorShared((state) =>
    getHasEssentialsOnlyShipmentFee(state, { shipmentID }),
  );
  const { essentialsFreeDeliveryMinimumBasket } = useQueryEssentialsFeeConfigs();
  const minimumFreeDeliveryThreshold = essentialsFreeDeliveryMinimumBasket?.value;
  const updatedQuantityBelowMinimum = useSelectorShared((state) =>
    shipmentID
      ? getUpdatedEssentialQuantityIsBelowFreeDeliveryMinimum(state, {
          id: shipmentID,
          initialQuantity,
          updatedQuantity: quantity,
          essentialPrice,
          minimumThreshold: minimumFreeDeliveryThreshold || 0,
        })
      : false,
  );

  useEffect(() => {
    const shouldShowWarning =
      quantityChanged && Boolean(shipmentID) && updatedQuantityBelowMinimum && !shipmentHasEssentialsOnlyFee;
    if (shouldShowWarning !== showMinimumWarning) {
      setShowMinimumWarning(shouldShowWarning);
    }
  }, [updatedQuantityBelowMinimum, quantityChanged, shipmentID, showMinimumWarning, shipmentHasEssentialsOnlyFee]);

  if (!showMinimumWarning) return null;

  return (
    <>
      <InlineAlert type="warning">
        <Description>
          This will make your subtotal under ${minimumFreeDeliveryThreshold}. A $9.99 delivery fee will be added to your
          order.
        </Description>
      </InlineAlert>
      <MdSpacing />
    </>
  );
};
