import {
  ActionSheetContext,
  ActionSheetV2,
  AltoIcon,
  Button,
  Description,
  ListItem,
  MdPadding,
  XxsSpacing,
} from '@alto/design-system';
import React, { useContext } from 'react';
import syringeVials from '~shared/assets/images/syringe-vials.png';
import { useFetchAncillariesByPrescriptionId } from '../queries/queries';

type Props = {
  readonly onBack?: () => void;
  readonly onPress?: () => void;
  readonly prescriptionID: number;
};

export const AncillaryItemsActionSheet = ({ onBack, onPress, prescriptionID }: Props) => {
  const { ancillaries } = useFetchAncillariesByPrescriptionId({ prescriptionId: prescriptionID });
  const { closeActionSheet } = useContext(ActionSheetContext);

  return (
    <ActionSheetV2
      title="This medication includes complimentary supplies"
      analyticsName="checkout ancillary items"
      illustrationSrc={syringeVials}
      illustrationAccessibilityLabel="syringe and vials"
      handleBack={onBack}
      handleClose={onBack}
      buttons={[
        <Button
          key="got-it"
          label="Got it"
          onPress={onPress ?? closeActionSheet}
        />,
      ]}
    >
      <MdPadding>
        <ListItem
          title="Supplies included:"
          LeftContent={<AltoIcon name="syringe-duo" />}
          centerVertically
          fullBleed
          descriptions={ancillaries.map((ancillary) => {
            return (
              <>
                <XxsSpacing />
                <Description key={ancillary.description}>• {ancillary.description}</Description>
              </>
            );
          })}
        />
      </MdPadding>
    </ActionSheetV2>
  );
};
