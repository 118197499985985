// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Body, Button, LgPadding, MdSpacing } from '@alto/design-system';
import React, { useContext } from 'react';
import flaskTestTube from '~shared/assets/images/flask-test-tube.png';

type Props = {
  readonly medicationName: string | undefined;
  readonly onPress?: () => void;
  readonly onBack?: () => void;
};

export const CompoundedMedicationActionSheet = ({ medicationName, onBack, onPress }: Props) => {
  const { closeActionSheet } = useContext(ActionSheetContext);

  return (
    <ActionSheetV2
      title="Compounded medication"
      illustrationSrc={flaskTestTube}
      illustrationAccessibilityLabel="test tube pours into a flask"
      buttons={[
        <Button
          key="got-it"
          label="Got it"
          onPress={onPress ?? closeActionSheet}
        />,
      ]}
      handleClose={onBack}
      onBack={onBack}
    >
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <Body>
          <Body fontFamily="semibold">{medicationName}</Body> will be specifically prepared for you by our partner
          compounding pharmacy.
        </Body>
        <MdSpacing />
        <Body>It will arrive with supplies to assist in administration.</Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
