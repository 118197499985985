// @owners { team: patients-team }
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { useSelectorShared } from '~shared/store';
import { useAddOnsContext } from '../../checkout';

export const useQueryEssentialsParams = () => {
  const { shipmentID } = useAddOnsContext();
  const shipment = useSelectorShared((state) => (shipmentID ? getShipmentWithID(state, { shipmentID }) : null));
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID }));

  return { addressID: address?.id, date: shipment?.scheduled_date, shipmentID };
};
