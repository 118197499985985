// @owners { team: patients-team }
import { AltoIcon, AltoSpinningLoader, PresentationListItem } from '@alto/design-system';
import React from 'react';

type Props = {
  show?: boolean;
  isLoading?: boolean;
};

export const AncillaryRow = ({ show, isLoading = false }: Props) => {
  if (isLoading) {
    return (
      <PresentationListItem
        LeftContent={
          <AltoIcon
            name="syringe"
            type="grey"
          />
        }
        text={<AltoSpinningLoader testID="alto-spinning-loader" />}
      />
    );
  }

  if (show) {
    return (
      <>
        <PresentationListItem
          LeftContent={
            <AltoIcon
              name="syringe"
              type="grey"
            />
          }
          text="Supplies included"
        />
      </>
    );
  }

  return null;
};
