import { Button, type ButtonProps, Row } from '@alto/design-system';
import { type Prescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/prescription';
import React from 'react';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useAddToCartInterstitialSheets } from '~web/features/checkout/hooks/useAddToCartInterstitialSheets';

export type Props = {
  readonly fullWidthButton?: boolean;
  readonly isProgynyOrderEarlyEligible?: boolean;
  readonly prescription: Prescription;
  readonly redirectToNative: boolean;
};

const AddToCart = ({ fullWidthButton, isProgynyOrderEarlyEligible, prescription, redirectToNative }: Props) => {
  const { initializeActionSheetsToShow, isLoading, isPrescriptionInCart } = useAddToCartInterstitialSheets({
    origin: ORIGIN_NAMES.HOMESCREEN,
    prescription: {
      ...prescription,
      auto_refill_eligible: !!prescription.auto_refill_eligible,
      image_url: prescription.image_url || '',
    },
  });

  const generateButtonProps = (): ButtonProps => {
    const buttonProps = {
      small: true,
      fullWidth: fullWidthButton,
      label: 'Add to cart',
      onPress: () => initializeActionSheetsToShow(),
      loading: isLoading,
    };

    if (redirectToNative) {
      return {
        ...buttonProps,
        disabled: true,
        label: isProgynyOrderEarlyEligible ? 'Order early on the Alto app' : 'Order on the Alto app',
      };
    }

    if (isPrescriptionInCart) {
      return {
        ...buttonProps,
        type: 'secondary',
        label: 'Remove from Cart',
      };
    }

    return buttonProps;
  };

  return (
    <Row left>
      <Button {...generateButtonProps()} />
    </Row>
  );
};

export default AddToCart;
