import { ANIMATION } from '@alto/design-library-tokens';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Animated, Platform, useWindowDimensions } from 'react-native';
import Modal from 'react-native-modal';
import styled from 'styled-components/native';
import { v4 as uuidv4 } from 'uuid';
import { Constants, useScreenSize } from '../../utils';
import { Column } from '../containers';

type NavigationStackComponent = React.ReactElement;

type SetActiveActionSheetOptions = {
  shouldRetainPreviousActionSheet?: boolean;
};

type ActionSheetProviderValue = {
  setActiveActionSheet: (
    ActionSheet: NavigationStackComponent | undefined,
    options?: SetActiveActionSheetOptions,
  ) => void;
  closeActionSheet: () => void;
  overrideActionSheetStack: (newStack: NavigationStackComponent[]) => void;
  goToPreviousActionSheet: () => void;
  restoreStack: <T extends string>(cacheKey: T) => void;
  saveStack: <T extends string>(cacheKey: T) => void;
};

export const ActionSheetContext = React.createContext<ActionSheetProviderValue>({
  setActiveActionSheet: () => null,
  closeActionSheet: () => null,
  overrideActionSheetStack: () => null,
  goToPreviousActionSheet: () => null,
  restoreStack: () => null,
  saveStack: () => null,
});

type InternalActionSheetPropsProviderValue = {
  createAnalyticsEvent: (actionSheetName: string) => void;
  handleBack?: () => void;
  handleClose: () => void;
  isFirstSheet?: boolean;
  fadeAnimationValue?: Animated.Value;
};

export const INTERNAL_ActionSheetPropsContext = React.createContext<InternalActionSheetPropsProviderValue>({
  createAnalyticsEvent: () => null,
  handleClose: () => null,
});

type ActionSheetProviderProps = {
  readonly children: React.ReactNode;
  readonly createAnalyticsEvent: (actionSheetName: string) => void;
};

type NavigationStackItem = {
  actionSheetComponent: NavigationStackComponent;
  managedProps?: InternalActionSheetPropsProviderValue;
  uuid: string;
  shouldRetainRender: boolean;
};

const StyledModal = styled(Modal)`
  justify-content: flex-end;
  margin: 0;
`;

const StyledVisibilityHack = styled(Column)<{ isHidden: boolean; isMDScreenOrBigger: boolean }>`
  max-height: 90%;
  justify-content: flex-end;
  ${({ isMDScreenOrBigger }) => {
    if (Platform.OS === 'web' && isMDScreenOrBigger) {
      return 'margin: auto;';
    }
    return '';
  }}
  ${({ isHidden }) =>
    isHidden &&
    `
    opacity: 0;
    z-index: -1;
    height: 0;
  `};
`;

const modalPropsMobile: Partial<React.ComponentProps<typeof Modal>> = {
  animationInTiming: ANIMATION.SPEEDS.FAST,
  animationOutTiming: ANIMATION.SPEEDS.SLOWEST,
};

const modalPropsDesktopWeb: Partial<React.ComponentProps<typeof Modal>> = {
  animationIn: 'fadeIn',
  animationOut: 'fadeOut',
};

export const ActionSheetProvider = ({ children, createAnalyticsEvent }: ActionSheetProviderProps) => {
  const { width, height } = useWindowDimensions();
  const fadeAnimationValue = useRef(new Animated.Value(1)).current;
  const [navigationStack, setNavigationStack] = useState<NavigationStackItem[]>([]);
  // Used for reading the navigationStack without triggering a re-render.
  const virtualizedNavigationStack = useRef<NavigationStackItem[]>(navigationStack);
  virtualizedNavigationStack.current = navigationStack;
  const cache = useRef<Record<string, NavigationStackItem[]>>({});
  const { isMDScreenOrBigger } = useScreenSize();

  const { actionSheetComponent, managedProps } = navigationStack[navigationStack.length - 1] || {};
  const context = useMemo(
    () => managedProps || { handleClose: () => null, createAnalyticsEvent: () => null },
    [managedProps],
  );

  const fadeIn = useCallback(() => {
    // skip animations in tests
    if (process?.env?.JEST_WORKER_ID) {
      return;
    }
    Animated.timing(fadeAnimationValue, {
      toValue: 1,
      duration: ANIMATION.SPEEDS.FAST,
      useNativeDriver: Constants.useNativeDriver,
    }).start();
  }, [fadeAnimationValue]);

  const handleTransition = useCallback(
    (newStackSetter: Parameters<typeof setNavigationStack>[0]) => {
      // skip animations in tests
      if (process?.env?.JEST_WORKER_ID) {
        setNavigationStack(newStackSetter);
        fadeIn();
        return;
      }

      Animated.timing(fadeAnimationValue, {
        toValue: 0,
        duration: ANIMATION.SPEEDS.FAST,
        useNativeDriver: Constants.useNativeDriver,
      }).start(() => {
        setNavigationStack(newStackSetter);
        fadeIn();
      });
    },
    [fadeAnimationValue, fadeIn],
  );

  const handleBack = useCallback(() => {
    handleTransition((prevStack) => prevStack.slice(0, prevStack.length - 1));
  }, [handleTransition]);

  const handleClose = useCallback(() => {
    handleTransition([]);
  }, [handleTransition]);

  const getManagedProps = useCallback(
    (isFirstSheet: boolean) => ({
      handleBack: isFirstSheet ? undefined : handleBack,
      handleClose,
      fadeAnimationValue,
      createAnalyticsEvent,
      isFirstSheet,
    }),
    [handleBack, handleClose, fadeAnimationValue, createAnalyticsEvent],
  );

  const handleSetActiveActionSheet = useCallback(
    (NextActionSheetComponent: NavigationStackComponent | undefined, options?: SetActiveActionSheetOptions) => {
      if (!NextActionSheetComponent) {
        handleTransition([]);
        return;
      }

      const willBeFirstSheet = virtualizedNavigationStack.current.length === 0;

      handleTransition((prevStack) => {
        const nextNavStackItem = {
          actionSheetComponent: NextActionSheetComponent,
          managedProps: getManagedProps(willBeFirstSheet),
          uuid: uuidv4(),
          shouldRetainRender: false,
        };
        if (options?.shouldRetainPreviousActionSheet) {
          const previousNavStackItem = {
            ...prevStack[prevStack.length - 1],
            shouldRetainRender: true,
          };
          const restOfStack = prevStack.slice(0, prevStack.length - 1);
          return [...restOfStack, previousNavStackItem, nextNavStackItem];
        }
        return [...prevStack, nextNavStackItem];
      });
    },
    [handleTransition, getManagedProps],
  );

  const overrideActionSheetStack = useCallback(
    (newStack: NavigationStackComponent[]) => {
      const internalStack = newStack.map((stackItem, i) => ({
        actionSheetComponent: stackItem,
        managedProps: getManagedProps(i === 0),
        uuid: uuidv4(),
        shouldRetainRender: false,
      }));

      handleTransition(internalStack);
    },
    [getManagedProps, handleTransition],
  );

  const goToPreviousActionSheet = useCallback(() => {
    handleBack();
  }, [handleBack]);

  const closeActionSheet = useCallback(() => {
    handleSetActiveActionSheet(undefined);
  }, [handleSetActiveActionSheet]);

  const saveStack = <T extends string>(cacheKey: T) => {
    cache.current[cacheKey] = virtualizedNavigationStack.current;
  };
  const saveStackMemoized = useCallback(saveStack, []);

  const restoreStack = <T extends string>(cacheKey: T) => {
    setNavigationStack(cache.current[cacheKey] ?? []);
    cache.current[cacheKey] = [];
  };
  const restoreStackMemoized = useCallback(restoreStack, []);

  const memoizedValue = useMemo(
    () => ({
      setActiveActionSheet: handleSetActiveActionSheet,
      overrideActionSheetStack,
      closeActionSheet,
      goToPreviousActionSheet,
      restoreStack: restoreStackMemoized,
      saveStack: saveStackMemoized,
      isFirstSheet: virtualizedNavigationStack.current.length === 0,
    }),
    [
      handleSetActiveActionSheet,
      closeActionSheet,
      overrideActionSheetStack,
      goToPreviousActionSheet,
      restoreStackMemoized,
      saveStackMemoized,
    ],
  );

  const modalProps = isMDScreenOrBigger ? modalPropsDesktopWeb : modalPropsMobile;

  return (
    <ActionSheetContext.Provider value={memoizedValue}>
      {children}
      <INTERNAL_ActionSheetPropsContext.Provider value={context}>
        {!!actionSheetComponent && (
          <StyledModal
            {...modalProps}
            avoidKeyboard
            isVisible
            onBackdropPress={managedProps?.handleClose}
            onSwipeComplete={managedProps?.handleClose}
            propagateSwipe
            swipeDirection="down"
            deviceHeight={height}
            deviceWidth={width}
          >
            {navigationStack
              .map((actionSheetObject, index) => {
                if (
                  !actionSheetObject.actionSheetComponent ||
                  (!actionSheetObject.shouldRetainRender && index !== navigationStack.length - 1)
                ) {
                  return null;
                }
                return (
                  <StyledVisibilityHack
                    key={actionSheetObject.uuid || actionSheetObject.actionSheetComponent.key}
                    isHidden={index !== navigationStack.length - 1}
                    isMDScreenOrBigger={isMDScreenOrBigger}
                  >
                    {actionSheetObject.actionSheetComponent}
                  </StyledVisibilityHack>
                );
              })
              .filter(Boolean)}
          </StyledModal>
        )}
      </INTERNAL_ActionSheetPropsContext.Provider>
    </ActionSheetContext.Provider>
  );
};
