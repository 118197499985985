// @owners { team: patients-team }
import {
  ActionSheetContext,
  AltoIcon,
  Description,
  InlineAlert,
  Link,
  Row,
  XsPadding,
  XxsPadding,
} from '@alto/design-system';
import React, { useContext } from 'react';
import { formatRelativeDate } from '~shared/helpers/date';
import { CartMixedAvailabilityActionSheet } from './CartMixedAvailabilityActionSheet';

export const CartMixedAvailabilityAlert = ({ date }: { date: string }) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  return (
    <>
      <InlineAlert type="warning">
        <Row
          centerVertically
          centerHorizontally
          testID="cart-mixed-availability-alert"
        >
          <AltoIcon
            name="calendar-small"
            type="grey"
          />
          <XxsPadding />
          <Description>{`Available ${formatRelativeDate(date, { abbreviated: true, includeRelative: true })} • `}</Description>
          <Link
            textSize="mini"
            onPress={() => {
              setActiveActionSheet(<CartMixedAvailabilityActionSheet date={date} />);
            }}
          >
            Details
          </Link>
        </Row>
      </InlineAlert>
      <XsPadding />
    </>
  );
};
