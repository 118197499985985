// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Body, LgPadding } from '@alto/design-system';
import React, { useContext } from 'react';
import { formatRelativeDate } from '~shared/helpers/date';

type Props = {
  date: string;
};

export const CartMixedAvailabilityActionSheet = ({ date }: Props) => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  const formattedDate = formatRelativeDate(date, { abbreviated: true, includeRelative: true });

  return (
    <ActionSheetV2
      onBack={closeActionSheet}
      title={`Cart available ${formattedDate}`}
      analyticsName="mixed availability dates in cart"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Body>
          Your medications have different availability dates. To get a medication sooner, remove medication(s) with a
          later availability date and order them separately.
        </Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
