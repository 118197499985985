// @owners { team: patients-team }
import { AltoSpinningLoader, Description } from '@alto/design-system';
import {
  BackorderedWarning,
  CartMixedAvailabilityAlert,
  useBackorderedMedications,
  useEssentialsCartThreshold,
} from '@alto/features';
import React from 'react';
import { formattedNextAvailableDate } from '~shared/features/next-available-date/helpers/formattedNextAvailableDate';
import { useQueryNextAvailableDateForOrder } from '~shared/features/next-available-date/queries/useQueryNextAvailableDateForOrder';
import { getNextAvailableDatesByPrescriptionID } from '~shared/features/next-available-date/selectors/getNextAvailableDatesByPrescriptionID';
import { useSelectorShared } from '~shared/store';

type Props = {
  readonly prescriptionIDs: number[];
};

export const CartFooterOverline = ({ prescriptionIDs }: Props) => {
  const { showEssentialsThreshold, orderMinimumRemainder, minimumFooterCopy } = useEssentialsCartThreshold();
  const { nextAvailableDate, isLoading } = useQueryNextAvailableDateForOrder();

  const { date = '', reason = '' } = nextAvailableDate?.earliest || {};
  const nextDate = date ? formattedNextAvailableDate(date, reason) : null;
  const backorderedMedicationIds = useBackorderedMedications(prescriptionIDs);
  const hasBackorderedMedications = backorderedMedicationIds.length > 0;

  // Determine if we need to show the mixed next available date alert.
  const nextAvailableDatesByPrescriptionID = useSelectorShared(getNextAvailableDatesByPrescriptionID);
  const earliestNextAvailableDates = prescriptionIDs.map((prescriptionId) => {
    return nextAvailableDatesByPrescriptionID[prescriptionId]?.earliest.date;
  }, []);
  const hasMixedNextAvailableDate = earliestNextAvailableDates.some((date) => date !== earliestNextAvailableDates[0]);
  const maxDate = earliestNextAvailableDates.reduce((a, b) => (a > b ? a : b), '');

  if (hasBackorderedMedications) {
    return <BackorderedWarning prescriptionIds={backorderedMedicationIds} />;
  }

  if (showEssentialsThreshold) {
    return (
      <Description center>
        {minimumFooterCopy} <Description fontFamily="semibold">${orderMinimumRemainder}</Description> more to checkout
      </Description>
    );
  }

  if (hasMixedNextAvailableDate && maxDate) {
    return <CartMixedAvailabilityAlert date={maxDate} />;
  }

  if (!isLoading) {
    return nextDate ? <Description>{nextDate}</Description> : null;
  }

  return <AltoSpinningLoader />;
};
