import { BORDERS, COLORS, SIZES, SPACING } from '@alto/design-library-tokens';
import React, { useCallback, useEffect, useRef } from 'react';
import { Animated, type DimensionValue, Platform, View, type ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { useScreenSize } from '../../utils';
import { Column } from '../containers';
import { Illustration } from '../images';
import { INTERNAL_ActionSheetPropsContext } from './ActionSheetProvider';
import { BottomSheet, type BottomSheetProps } from './BottomSheet';
import { type ActionSheetProps } from './DEPRECATED_ActionSheet';

export type ActionSheetV2Props = Partial<
  Omit<BottomSheetProps, 'alwaysScrollable' | 'hasScrollableContents' | 'dismissible'>
> &
  Pick<ActionSheetProps, 'illustrationSrc' | 'illustrationXl'> & {
    readonly illustrationAccessibilityLabel?: string;

    readonly fullscreen?: boolean;

    /**
     * Callback for any side-effects
     */
    readonly onBack?: () => void;

    /**
     * Type of action sheet to send as part of analytics, takes precedence over title
     */
    readonly analyticsName?: string;
  };

/**
 * !⚠️ Don't set `overflow: hidden` on the container or the illustration will be cut off.
 */
const defaultStyles: ViewStyle = {
  backgroundColor: COLORS.BACKGROUND_COLORS.WHITE,
  flexShrink: 1,
};

const largeScreenStyles: ViewStyle = {
  ...defaultStyles,
  transform: 'translate(-50%, -50%)',
  left: '50%',
  top: '50%',
  position: 'fixed',
  width: '600px',
  borderRadius: BORDERS.RADIUS.XL.px,
  margin: 'auto',
} as any; // this should be done within a styled component, not as a JS object

const smallScreenStyles: ViewStyle = {
  ...defaultStyles,
  borderTopLeftRadius: BORDERS.RADIUS.XL.value,
  borderTopRightRadius: BORDERS.RADIUS.XL.value,
};

const getHeightProp = (fullscreen?: boolean): Record<never, never> | Record<'height', DimensionValue> => {
  if (!fullscreen) {
    return {};
  }

  if (Platform.OS !== 'web') {
    return { height: '100%' };
  }

  return { height: '90vh' };
};

const platformStyle = (isMDScreenOrBigger: boolean, fullscreen?: boolean): ViewStyle => {
  const heightProp = getHeightProp(fullscreen);
  if (Platform.OS !== 'web') {
    return { ...smallScreenStyles, ...heightProp };
  }

  const maxHeight = '90vh' as DimensionValue;
  if (isMDScreenOrBigger) {
    return { ...largeScreenStyles, ...heightProp, maxHeight };
  }

  return { ...smallScreenStyles, ...heightProp, maxHeight };
};

const IllustrationAnchor = styled(View)<{ illustrationXl?: boolean }>`
  position: absolute;
  right: ${SPACING.STATIC.LG.px};
  top: -${({ illustrationXl }) => (illustrationXl ? SIZES.ILLUSTRATION.XL.value : SIZES.ILLUSTRATION.LG.value) / 2}px;
  z-index: 1;
`;

const StyledAnimatedView = styled(Animated.View)<{ isMDScreenOrBigger: boolean; fullscreen?: boolean }>`
  flex-shrink: 1;
  flex-grow: 1;
  ${({ isMDScreenOrBigger }) => (isMDScreenOrBigger ? `padding-bottom: ${SPACING.STATIC.LG.px};` : '')}
`;

/**
 * Used to render an action sheet. As a layout component, it has several props that render content
 * in different places. The order is the following:
 *
 * ```
 * ┌────────────────────────────────────────illustrationSrc──┐
 * │                                                         │
 * │  Tag (optional)                                         │
 * │  title (large serif text)                               │
 * │  description (optional, small text)                     │
 * │  children (arbitrary content)                           │
 * │  buttons (optional)                                     │
 * │  footnote (optional arbitrary content below buttons)    │
 * │                                                         │
 * └─────────────────────────────────────────────────────────┘
 * ```
 */
export const ActionSheetV2 = ({
  handleBack: handleBackOverride,
  handleClose: handleCloseOverride,
  onBack,
  illustrationAccessibilityLabel,
  illustrationSrc,
  illustrationXl,
  buttons,
  description,
  footnote,
  Tag,
  title,
  children,
  fullscreen,
  analyticsName,
}: ActionSheetV2Props) => {
  const { handleBack, handleClose, fadeAnimationValue, createAnalyticsEvent, isFirstSheet } = React.useContext(
    INTERNAL_ActionSheetPropsContext,
  );
  const { isMDScreenOrBigger } = useScreenSize();

  const loaded = useRef(false);
  useEffect(() => {
    if (loaded.current) return;
    loaded.current = true;
    createAnalyticsEvent(analyticsName || title || 'Unknown');
  }, [createAnalyticsEvent, analyticsName, title]);

  const handleBackWrapper = useCallback(() => {
    // the handle back override use case is mainly for navigating to a totally different screen
    if (handleBackOverride) {
      handleBackOverride();

      // we also need to close the global action sheet or else it will be in mixed state.
      handleClose();
    } else {
      handleBack?.();
    }

    onBack?.();
  }, [handleBackOverride, onBack, handleClose, handleBack]);

  const handleCloseWrapper = useCallback(() => {
    handleClose();

    if (handleCloseOverride) {
      handleCloseOverride();
    }
  }, [handleClose, handleCloseOverride]);

  const handleEscapeKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e?.key === 'Escape') {
        handleCloseWrapper();
      }
    },
    [handleCloseWrapper],
  );

  return (
    <Column style={platformStyle(isMDScreenOrBigger, fullscreen)}>
      {!!illustrationSrc && (
        <IllustrationAnchor illustrationXl={illustrationXl}>
          <Illustration
            size={illustrationXl ? 'xl' : 'lg'}
            source={illustrationSrc}
            accessibilityLabel={illustrationAccessibilityLabel}
          />
        </IllustrationAnchor>
      )}
      <StyledAnimatedView
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ opacity: process.env.JEST_WORKER || Platform.OS === 'web' ? 1 : fadeAnimationValue }}
        fullscreen={fullscreen}
        isMDScreenOrBigger={isMDScreenOrBigger}
        // @ts-expect-error this does exist on web, couldn't get global TS override to work with animated component
        onKeyUp={Platform.OS === 'web' ? handleEscapeKeyPress : undefined}
      >
        <BottomSheet
          alwaysScrollable
          buttons={buttons}
          description={description}
          footnote={footnote}
          handleBack={handleBackOverride || handleBack ? handleBackWrapper : undefined}
          handleClose={handleCloseWrapper}
          isFirstSheet={!!isFirstSheet}
          Tag={Tag}
          title={title}
        >
          {children}
        </BottomSheet>
      </StyledAnimatedView>
    </Column>
  );
};
