import { PALETTE } from './palette';

export const BACKGROUND_COLORS = {
  DANGER_DARK: PALETTE.DANGER.DARK,
  DANGER_LIGHT: PALETTE.DANGER.LIGHT,
  DANGER_LIGHTER: PALETTE.DANGER.LIGHTER,
  DANGER_LIGHTEST: PALETTE.DANGER.LIGHTEST,
  DANGER: PALETTE.DANGER.DEFAULT,
  GREY_LIGHTER: PALETTE.GREYSCALE.LIGHTER,
  GREY_LIGHTEST: PALETTE.GREYSCALE.LIGHTEST,
  GREY: PALETTE.GREYSCALE.DEFAULT,
  MODAL_BACKGROUND: PALETTE.GREYSCALE.BLACK_TRANSPARENT,
  PINK_LIGHT: PALETTE.PINK.LIGHT,
  PINK_LIGHTER: PALETTE.PINK.LIGHTER,
  PINK_LIGHTEST: PALETTE.PINK.LIGHTEST,
  PRIMARY_DARK: PALETTE.PRIMARY.DARK,
  PRIMARY_LIGHT: PALETTE.PRIMARY.LIGHT,
  PRIMARY_LIGHTER: PALETTE.PRIMARY.LIGHTER,
  PRIMARY_LIGHTEST: PALETTE.PRIMARY.LIGHTEST,
  PRIMARY: PALETTE.PRIMARY.DEFAULT,
  SECONDARY_LIGHT: PALETTE.SECONDARY.LIGHT,
  SECONDARY_LIGHTER: PALETTE.SECONDARY.LIGHTER,
  SECONDARY_LIGHTEST: PALETTE.SECONDARY.LIGHTEST,
  SECONDARY: PALETTE.SECONDARY.DEFAULT,
  SUCCESS_LIGHT: PALETTE.SUCCESS.LIGHT,
  SUCCESS_LIGHTER: PALETTE.SUCCESS.LIGHTER,
  SUCCESS_LIGHTEST: PALETTE.SUCCESS.LIGHTEST,
  SUCCESS: PALETTE.SUCCESS.DEFAULT,
  TERTIARY_LIGHT: PALETTE.TERTIARY.LIGHT,
  TERTIARY_LIGHTER: PALETTE.TERTIARY.LIGHTER,
  TERTIARY_LIGHTEST: PALETTE.TERTIARY.LIGHTEST,
  TRANSPARENT: PALETTE.GREYSCALE.TRANSPARENT,
  WARNING_LIGHT: PALETTE.WARNING.LIGHT,
  WARNING_LIGHTER: PALETTE.WARNING.LIGHTER,
  WARNING_LIGHTEST: PALETTE.WARNING.LIGHTEST,
  WARNING: PALETTE.WARNING.DEFAULT,
  WHITE: PALETTE.GREYSCALE.WHITE,
} as const;
