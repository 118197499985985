// @owners { team: patients-team }
import { Body, Card, LgPadding, MdSpacing, Row } from '@alto/design-system';
import React from 'react';

export const SharpsContainerDetailCard = () => {
  return (
    <Card>
      <LgPadding>
        <Row
          top
          spaceBetween
        >
          <Row
            flexGrow={1}
            flexShrink={1}
            flexBasis="0%"
          >
            <Body>Sharps Container</Body>
            <MdSpacing />
          </Row>
          <Body fontFamily="bold">Free</Body>
        </Row>
      </LgPadding>
    </Card>
  );
};
