import { COLORS } from '@alto/design-library-tokens';
import { ActionSheetContext, Body, Column, Description, Toast, ToastContext } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import {
  MedSyncDeliveryRescheduleActionSheet,
  getOrderBundlingCheckoutOrigin,
  useAddToShipment,
  useCreateOrder,
  useGetOrderParams,
  // eslint-disable-next-line import/no-deprecated
  useSaveOrder,
} from '@alto/features';
import { useNavigation } from '@alto/navigation';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { push } from 'react-router-redux';
import {
  clearSaveOrderError,
  exitEditingOrder,
  fetchWindows,
  resetOrdersByIndex,
  setOrderAtIndex,
  setOrderIndex,
  showValidationErrors,
  updateOrder,
} from '~shared/actions/cart';
import { openModal } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
import { CHECKOUT_ROUTE } from '~shared/constants';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { useCartUsers } from '~shared/features/cart/hooks/useCartUsers';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { getTipAnalyticsProps } from '~shared/features/checkout/helpers';
import { useCheckoutCourierTipping } from '~shared/features/checkout/hooks/useCheckoutCourierTipping';
import { getIsOrderBundling } from '~shared/features/checkout/selectors/getCheckoutFlow';
import { getOrder, getOrderAddress } from '~shared/features/checkout/selectors/getOrder';
import { getOrdersByIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { getIsPriceUnconfirmed } from '~shared/features/checkout/selectors/getPaymentBreakdown';
import { type CheckoutValidationErrors } from '~shared/features/checkout/types';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentTipsForUser } from '~shared/features/courier-tips/actions';
import { INITIAL_TIP_AMOUNT, NEW_INITIAL_TIP_AMOUNT } from '~shared/features/courier-tips/constants';
import { getTipAmountWithDefault } from '~shared/features/courier-tips/helpers';
import { getNextDeliveryByPrescriptionID } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import getShipmentID from '~shared/features/next-available-date/selectors/getShipmentId';
import { getOrderPricing } from '~shared/features/pricing/selectors/getPricing';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useAnalytics, usePrevious } from '~shared/hooks';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { exitOrderBundling } from '~shared/reducers/ui/checkoutFlow';
import { getRouteState } from '~shared/selectors/getRouteState';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type DeliveryWithPrescription } from '~shared/types';
import { Checkout } from './Checkout';

export const CheckoutContainer = () => {
  const { value: newDefaultTipping, isLoading: newDefaultTippingLoading } =
    Experimentation.useFeatureFlag('default_tipping');
  const deletePaymentMethodError = useSelectorShared((state) => state.ui.errors.deletePaymentMethodError);
  const order = useSelectorShared(getOrder);
  const initialTipAmount = newDefaultTipping && !newDefaultTippingLoading ? NEW_INITIAL_TIP_AMOUNT : INITIAL_TIP_AMOUNT;
  const tipAmount = getTipAmountWithDefault(order.tip_amount, initialTipAmount);
  const orderPricing = useSelectorShared(getOrderPricing);
  const isPriceUnconfirmed = useSelectorShared(getIsPriceUnconfirmed);
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const userID = useSelectorShared(getCurrentUserID);
  const shipmentID = useSelectorShared(getShipmentID);
  const address = useSelectorShared(getOrderAddress);
  const { value: isOrdersEndpointEnabled } = Experimentation.useFeatureFlag('orders_endpoint_web');
  const { createOrderParams } = useGetOrderParams();
  const { createOrder } = useCreateOrder({ isEditingOrder });
  const ordersByIndex = useSelectorShared(getOrdersByIndex);
  const { navigate } = useNavigation();
  // maintain orderIndex in the route state in order to navigate to "/checkout" page multiple times
  const routeState = useSelectorShared(getRouteState);
  const orderIndex: number = routeState?.orderIndex || 0;
  const dispatch = useDispatchShared();
  const { addToast } = useContext(ToastContext);
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();
  const { deliveryMethods } = useCartDeliveryMethods();
  const hasMultipleShipments = deliveryMethods.length > 1;
  const { isPhotoIDRequired } = useCartUsers();
  const { cartItems } = useCartData();
  const { prescriptionIDs: unbundledPrescriptionIDs, hasCompounds } = usePrescriptionsInCart();
  // for split shipments, pass deliveryMethod to determine if tippable shipment
  const { isEligibleForTipping: isTippable } = useCheckoutCourierTipping({
    deliveryMethod: deliveryMethods[orderIndex],
  });
  const nextDeliveryByPrescriptionID = useSelectorShared(getNextDeliveryByPrescriptionID);
  const deliveries = useSelectorShared((state) =>
    getDeliveriesForShipmentID(state, { shipmentID: shipmentID ? +shipmentID : null }),
  );
  const isOrderBundling = useSelectorShared(getIsOrderBundling);
  const orderBundlingOrigin = getOrderBundlingCheckoutOrigin({ isEditingOrder });

  const { handleAddToUpcomingDelivery } = useAddToShipment({ origin: orderBundlingOrigin, address });
  // eslint-disable-next-line import/no-deprecated
  const { saveOrderApiCall } = useSaveOrder();
  const cartResourceIds = useMemo(() => cartItems.map((cartItem) => cartItem.resource_id), [cartItems]);
  const tipAnalyticsProps = getTipAnalyticsProps({
    shipmentID,
    cartResourceIds,
    isEditingOrder,
    facilityId: address?.dispensing_facility_id,
  });

  const handleEditTipAmount = useCallback(
    (tipAmount: number) => {
      if (order.tip_amount !== tipAmount) {
        dispatch(
          updateOrder({
            tip_amount: tipAmount,
          }),
        );
      }
    },
    [order.tip_amount, dispatch],
  );

  // IMPORTANT: when orderIndex changes in route state due to navigation (i.e. browser back button)
  // ensure the redux value stays in sync
  useEffect(() => {
    dispatch(setOrderIndex(orderIndex));
  }, [orderIndex, dispatch]);

  useEffect(() => {
    // When navigating to "/checkout" multiple times, the scroll position is retained
    // Scroll to top of screen after the first shipment
    if (orderIndex > 0) {
      window.scrollTo(0, 0);
    }
  }, [orderIndex]);

  useEffect(() => {
    // need to confirm patient is in treatment group of experiment before showing courier tip affordances
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (isTippable && !isEditingOrder) {
      // don't update tip if already set. this can happen when going from checkout -> cart -> checkout
      if (!order.tip_amount) {
        handleEditTipAmount(initialTipAmount);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // We fetch the dates for any `unbundledPrescriptions` since pt can remove deliveries from their shipment
    // and we will need the NAD for the remaining deliveries to inform pt about their options
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchNextAvailableDates({ unbundledPrescriptionIDs }));
    dispatch(fetchWindows());

    // the patient may still be in the control group of the experiment, but one extra fetch won't kill us
    if (isTippable && userID) {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchShipmentTipsForUser(userID));
    }

    return () => {
      if (isEditingOrder) {
        dispatch(resetOrdersByIndex());
        dispatch(clearSaveOrderError());
        dispatch(exitEditingOrder());
        return;
      }
      dispatch(resetOrdersByIndex());
      dispatch(clearSaveOrderError());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(exitOrderBundling());
    };
  }, [dispatch]);

  useEffect(() => {
    if (deletePaymentMethodError?.details?.message) {
      addToast(
        <Toast
          variant="error"
          dismissible
        >
          <Column>
            <Body color={COLORS.TEXT_COLORS.WHITE}>Whoops!</Body>
            <Description color={COLORS.TEXT_COLORS.WHITE}>{deletePaymentMethodError?.details?.message}</Description>
          </Column>
        </Toast>,
      );
    }
  }, [deletePaymentMethodError, addToast]);

  const originalAddressID = useRef(order.address_id);
  const previousAddressID = usePrevious(order.address_id);

  // 🐾 track when the address is auto-selected initially
  useEffect(() => {
    if (!originalAddressID.current) return;
    trackEvent({
      event: EVENTS.CHECKOUT__ADDRESS_SELECTED,
      params: {
        'auto-selected': true,
        address_id: originalAddressID.current,
        origin: isEditingOrder ? 'edit delivery' : 'checkout',
      },
    });
  }, [isEditingOrder, trackEvent]);

  // 🐾 track when the patient selects a different address
  useEffect(() => {
    if (previousAddressID && previousAddressID !== order.address_id) {
      trackEvent({
        event: EVENTS.CHECKOUT__ADDRESS_SELECTED,
        params: {
          'auto-selected': false,
          address_id: order.address_id,
          origin: isEditingOrder ? 'edit delivery' : 'checkout',
        },
      });
    }
  }, [isEditingOrder, order.address_id, previousAddressID, trackEvent]);

  const originalDate = useRef(order.date);
  const previousDate = usePrevious(order.date);

  // 🐾 track when the date is auto-selected initially
  useEffect(() => {
    if (!originalDate.current) return;
    trackEvent({
      event: EVENTS.CHECKOUT__DATE_SELECTED,
      params: {
        'auto-selected': true,
        date: originalDate.current,
        origin: isEditingOrder ? 'edit delivery' : 'checkout',
      },
    });
  }, [isEditingOrder, trackEvent]);

  // 🐾 track when the patient selects a different date
  useEffect(() => {
    if (previousDate && previousDate !== order.date) {
      trackEvent({
        event: EVENTS.CHECKOUT__DATE_SELECTED,
        params: {
          'auto-selected': false,
          date: order.date,
          origin: isEditingOrder ? 'edit delivery' : 'checkout',
        },
      });
    }
  }, [isEditingOrder, previousDate, order.date, trackEvent]);

  const saveOrder = async () => {
    let shipmentID: number | null = null;

    // TODO: Remove this unused flag
    if (isOrdersEndpointEnabled) {
      const orderParams = createOrderParams();
      const response = await createOrder(orderParams);

      if (response.errors) return;

      const createdShipmentIDs = response.data?.shipment_ids ?? [];
      shipmentID = createdShipmentIDs?.length === 1 ? createdShipmentIDs[0] : null;
    } else {
      const response = await saveOrderApiCall();

      if ('error' in response) return;

      shipmentID = response.shipment_id;
    }

    const previousRoute = CHECKOUT_ROUTE;

    if (shipmentID && !hasCompounds) {
      dispatch(push({ pathname: `/store/order-success/${shipmentID}` }));
    } else {
      dispatch(
        push({
          pathname: '/orders',
          state: {
            previousRoute,
          },
        }),
      );
    }
  };

  const handleSaveOrder = (hasValidationErrors: boolean, validationErrors: CheckoutValidationErrors) => {
    unbundledPrescriptionIDs.forEach((prescriptionID) => {
      const event = createEvent(
        EVENTS.CHECKOUT__PLACE_ORDER_TAPPED,
        {
          origin: isEditingOrder ? 'edit delivery' : 'checkout',
          facility_id: address?.dispensing_facility_id,
        },
        {
          prescriptionId: prescriptionID,
        },
      );

      if (isEditingOrder) {
        event.shipmentId = Number(shipmentID);
        event.deliveryId = nextDeliveryByPrescriptionID[prescriptionID]?.id;
      }

      dispatch(sendAnalyticsEvent(event));
    });

    dispatch(setOrderAtIndex({ order, orderIndex: 0 }));

    const deliveryIsMedSyncInProgress = (delivery: DeliveryWithPrescription) => {
      return delivery.med_sync_status === 'short_fill' || delivery.med_sync_status === 'in_progress';
    };
    const medSyncDeliveries = deliveries.filter(deliveryIsMedSyncInProgress);

    if (hasValidationErrors) {
      dispatch(showValidationErrors());
      const message = Object.values(validationErrors)
        .flat()
        .map((error) => error.message)
        .join(' ');
      dispatch(
        sendAnalyticsEvent(
          createEvent(EVENTS.CHECKOUT_VALIDATION_ERROR, {
            ...tipAnalyticsProps,
            errorMessage: message,
          }),
        ),
      );
      addToast(
        <Toast
          dismissible
          duration={5000}
          variant="error"
        >
          <Column>
            <Body color={COLORS.TEXT_COLORS.WHITE}>Whoops!</Body>
            <Description color={COLORS.TEXT_COLORS.WHITE}>{message.trim()}</Description>
          </Column>
        </Toast>,
      );
    } else if (isPhotoIDRequired) {
      dispatch(openModal('PHOTO_ID_UPLOAD_MODAL'));
    } else if (hasMultipleShipments && orderIndex < deliveryMethods.length - 1) {
      const nextOrderIndex = orderIndex + 1;
      dispatch(setOrderIndex(nextOrderIndex));
      // set redux cart ("order") with next temp order to reflect choices from next screen
      const nextOrder = ordersByIndex[nextOrderIndex];
      // ensure the address is the same in case the patient has changed it by going between screens
      // reset the order date so that the next available date is correct
      if (nextOrder) dispatch(updateOrder({ ...nextOrder, address_id: order.address_id, date: undefined }));
      navigate('RouteCheckout', { orderIndex: nextOrderIndex });
    } else if (medSyncDeliveries.length > 0) {
      const medicationNames = medSyncDeliveries
        .map((delivery) => delivery.medication_name)
        .filter((medicationName): medicationName is string => !!medicationName);

      setActiveActionSheet(
        <MedSyncDeliveryRescheduleActionSheet
          onClose={closeActionSheet}
          onSubmit={() => {
            saveOrder();
            closeActionSheet();
          }}
          medicationNames={medicationNames}
        />,
      );
    } else if (isOrderBundling) {
      handleAddToUpcomingDelivery();
    } else {
      saveOrder();
    }
  };

  return (
    <Checkout
      isEditingOrder={isEditingOrder}
      isTippable={isTippable}
      tipAmount={tipAmount}
      isPriceUnconfirmed={isPriceUnconfirmed}
      orderPricing={orderPricing}
      orderIndex={orderIndex}
      items={cartItems}
      handleEditTipAmount={handleEditTipAmount}
      handleSaveOrder={handleSaveOrder}
      shipmentID={shipmentID}
      tipAnalyticsProps={tipAnalyticsProps}
    />
  );
};
