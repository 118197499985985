// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  AltoIcon,
  Body,
  Description,
  InlineAlert,
  LgPadding,
  LgSpacing,
  MdSpacing,
  Row,
  SmSpacing,
} from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { getItemQuantityForAddOnOtc } from '~shared/features/checkout/helpers';
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import { type AnalyticsProps } from '~shared/features/essentials/AnalyticsProps';
import { useEssentialPrescriptionByProductID } from '~shared/features/essentials/hooks';
import { getUpdatedEssentialQuantityIsBelowMinimum } from '~shared/features/pricing/selectors/getEssentialsOnlyOrderBelowMinimum';
import { useEssentialsDeliveryWaiveFeeWarning } from '~shared/features/same-day-delivery/hooks/useEssentialsDeliveryWaiveFeeWarning';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { useAddOnsContext } from '../../checkout';
import { useEssentialsCartThreshold } from '../../essentials/hooks/useEssentialsCartThreshold';
import { DeliveryWaivedFeeWarning } from '../../same-day-delivery';
import { useQueryEssentialsFeeConfigs } from '../queries/useQueryEssentialsFeeConfigs';
import { EssentialsQuantityToggle } from './EssentialsQuantityToggle';
import { EssentialsUpdateButton } from './EssentialsUpdateButton';
import { UpdateWillAddEssentialsFeeWarning } from './UpdateWillAddEssentialsFeeWarning';

type Props = {
  readonly essential: AddOnOtc;
  readonly analyticsProps: AnalyticsProps;
};

export const EssentialsItemUpdateActionSheet = ({ essential, analyticsProps }: Props) => {
  const { goBack } = useNavigation();
  const { trackPageView } = useAnalytics();
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { shipmentID, origin } = useAddOnsContext();
  const [showMinimumWarning, setShowMinimumWarning] = useState(false);
  const { essential: prescription } = useEssentialPrescriptionByProductID({
    productID: essential.product_id,
    shipmentID,
  });
  const { cartSize } = useCartData();
  const numberOfShipmentItems = useSelectorShared((state) => getDeliveriesForShipmentID(state, { shipmentID })).length;
  const numItems = shipmentID ? numberOfShipmentItems : cartSize;
  const isLastItem = numItems === 1;
  const initialQuantity = prescription ? getItemQuantityForAddOnOtc(prescription, essential.quantity) : 1;
  const [quantity, setQuantity] = useState(initialQuantity);
  const quantityChanged = quantity !== initialQuantity;
  const { minimumThreshold } = useEssentialsCartThreshold();
  const { essentialsMinimumBasket } = useQueryEssentialsFeeConfigs();
  const { message, showDeliveryWaiveFeeWarning } = useEssentialsDeliveryWaiveFeeWarning({
    essential,
    initialQuantity,
    quantity,
    quantityChanged,
    shipmentID,
  });

  const updatedQuantityBelowMinimum = useSelectorShared((state) =>
    shipmentID
      ? getUpdatedEssentialQuantityIsBelowMinimum(state, {
          id: shipmentID,
          initialQuantity,
          updatedQuantity: quantity,
          essentialPrice: essential.price,
          minimumThreshold: essentialsMinimumBasket?.value || 0,
        })
      : false,
  );

  useEffect(() => {
    trackPageView({
      event: EVENTS.ESSENTIALS__UPDATE_ACTION_SHEET_VIEWED,
      params: {
        shipment_id: shipmentID,
        origin,
      },
    });
  }, [origin, shipmentID, trackPageView]);

  const handleQuantityChange = useCallback(
    (type: 'increase' | 'decrease') => () => {
      setQuantity((p) => Math.max(p + (type === 'increase' ? 1 : -1), 0));
    },
    [],
  );

  const onDelete = useCallback(() => {
    closeActionSheet();
    if (isLastItem) {
      goBack();
    }
  }, [goBack, closeActionSheet, isLastItem]);

  useEffect(() => {
    const shouldShowWarning = quantityChanged && Boolean(shipmentID) && updatedQuantityBelowMinimum;
    if (shouldShowWarning !== showMinimumWarning) {
      setShowMinimumWarning(shouldShowWarning);
    }
  }, [updatedQuantityBelowMinimum, quantityChanged, shipmentID, showMinimumWarning]);

  return (
    <ActionSheetV2
      title={`Edit ${essential.name}`}
      analyticsName="essentials update item"
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        {!prescription?.is_upcoming_delivery_paid_by_insurance && (
          <Row
            centerVertically
            spaceBetween
          >
            <Row>
              <AltoIcon name="pillbottle" />
              <MdSpacing />
              <Body
                color={COLORS.TEXT_COLORS.PRIMARY}
                fontFamily="bold"
              >
                Quantity
              </Body>
            </Row>
            <Row>
              <EssentialsQuantityToggle
                itemQuantity={quantity}
                onIncrease={handleQuantityChange('increase')}
                onDecrease={handleQuantityChange('decrease')}
              />
              <SmSpacing />
            </Row>
          </Row>
        )}
        <MdSpacing />
        {showMinimumWarning ? (
          <>
            <SmSpacing />
            <InlineAlert type="warning">
              <Description>A ${minimumThreshold} minimum subtotal applies to deliveries of Essentials</Description>
            </InlineAlert>
            <LgSpacing />
          </>
        ) : null}
        {showDeliveryWaiveFeeWarning ? <DeliveryWaivedFeeWarning message={message} /> : null}
        {!showMinimumWarning && (
          <UpdateWillAddEssentialsFeeWarning
            essentialPrice={essential.price}
            initialQuantity={initialQuantity}
            quantity={quantity}
            quantityChanged={quantityChanged}
            shipmentID={shipmentID}
          />
        )}
        <EssentialsUpdateButton
          essential={essential}
          analyticsProps={analyticsProps}
          itemQuantity={quantity}
          onClose={closeActionSheet}
          disabled={!quantityChanged || showMinimumWarning}
          onDelete={onDelete}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
