import { SPACING } from '@alto/design-library-tokens';
import { Card, LgPadding, MdSpacing, Separator, Tag, useScreenSize } from '@alto/design-system';
import { AddOnsContext, ShipmentInfo } from '@alto/features';
import { type UpcomingShipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/upcoming_shipment';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { ORIGINS } from '~shared/features/essentials/add_ons_consts';
import { getUpcomingOrderLabel } from '~shared/features/homescreen/utils';
import { getShipmentStatusString } from '~shared/features/shipments/helpers/getShipmentStatusConfig';
import { getWhatToExpectCopy } from '~shared/features/shipments/helpers/getWhatToExpectCopy';
import { useEligibleForMailAutoRefill } from '~shared/features/shipments/hooks/useEligibleForMailAutoRefill';
import { useHasCompoundPrescriptionsForShipment } from '~shared/features/shipments/hooks/useHasCompoundPrescriptionsForShipment';
import { useShipmentStatusMap } from '~shared/features/shipments/hooks/useShipmentStatusMap';
import { getShipmentWithID } from '~shared/features/shipments/selectors/getShipments';
import { getAccessibilityLabel, getIllustration } from '~shared/helpers/order';
import { useSelectorShared } from '~shared/store';
import { ShipmentSingleCarousel } from '~web/features/homescreen/components/ShipmentSingleCarousel';

const CustomCard = styledNative(Card)<{ isSMScreenOrBigger: boolean }>`
  margin: 0 0 ${SPACING.STATIC.XL.px};
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `margin: 0 0 ${SPACING.STATIC.LG.value * 2}px;`}
  z-index: 0;
`;

const CustomSeparator = styled(Separator)`
  margin: 0 -24px;
`;

/**
 * Required because when the Essentials carousel shows,
 * it pushes the card contents out of the card.
 */
const CarouselWrapper = styledNative(LgPadding)`
  width: 100%;
`;

type Props = {
  readonly upcomingShipment: UpcomingShipment;
};

export default function UpcomingShipment({ upcomingShipment }: Props) {
  const { isSMScreenOrBigger } = useScreenSize();
  const shipment = useSelectorShared((state) => getShipmentWithID(state, { shipmentID: upcomingShipment.id }));
  const hasCompound = useHasCompoundPrescriptionsForShipment({ shipmentID: upcomingShipment.id });
  const isEligibleForMailAutoRefill = useEligibleForMailAutoRefill({ shipment: upcomingShipment });
  const isShipmentEditable = upcomingShipment.editable;
  const isEssentialsAutoRefillOptInAllowed = isShipmentEditable && !hasCompound;
  const shipmentStatusMap = useShipmentStatusMap();
  const shipmentStatusString = getShipmentStatusString(shipment?.status, shipmentStatusMap, shipment?.is_pickup);
  const illustrationArgs = { method: shipment?.shipment_method, status: shipment?.status };
  const essentialsContext = useMemo(
    () =>
      ({
        shipmentID: shipment?.id,
        origin: ORIGINS.HOMESCREEN,
      }) as const,
    [shipment?.id],
  );

  return (
    <AddOnsContext.Provider value={essentialsContext}>
      <CustomCard
        title={getUpcomingOrderLabel({ isPickup: shipment?.is_pickup, status: shipment?.status })}
        description={
          isEligibleForMailAutoRefill
            ? undefined
            : getWhatToExpectCopy(shipment?.shipment_method, shipmentStatusMap, shipment?.status)
        }
        illustrationSrc={getIllustration(illustrationArgs)}
        illustrationAccessibilityLabel={getAccessibilityLabel(illustrationArgs)}
        isSMScreenOrBigger={isSMScreenOrBigger}
        tag={
          <Tag
            label={shipmentStatusString}
            type="recommended"
          />
        }
      >
        <CarouselWrapper topPadding={SPACING.STATIC.NONE}>
          <ShipmentInfo
            shipment={upcomingShipment}
            shipmentLabel=""
            isEligibleForMailAutoRefill={isEligibleForMailAutoRefill}
          />
          {isEssentialsAutoRefillOptInAllowed ? (
            <>
              <MdSpacing />
              <CustomSeparator />
              <MdSpacing />
              <ShipmentSingleCarousel />
            </>
          ) : null}
        </CarouselWrapper>
      </CustomCard>
    </AddOnsContext.Provider>
  );
}
