// @owners { team: patients-team }
import { Experimentation } from '@alto/experimentation';
import { getIsOrderBundling } from '~shared/features/checkout/selectors/getCheckoutFlow';
import { getIsOrderPickupMethod, getOrderDate } from '~shared/features/checkout/selectors/getOrder';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import { useSelectorShared } from '~shared/store';

export const useSaveOrderText = ({ isLastShipment }: { isLastShipment: boolean }): string => {
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const isOrderBundling = useSelectorShared(getIsOrderBundling);
  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);
  const orderDateSelected = useSelectorShared(getOrderDate);
  const { value: isPickupToggleEnabled } = Experimentation.useFeatureFlag('scheduling_module_pickup_toggle');

  let deliveryMethodCopy = '';
  if (isPickupToggleEnabled || orderDateSelected) {
    deliveryMethodCopy = isOrderPickupMethod ? 'pickup ' : 'delivery ';
  }

  if (!isLastShipment) {
    return 'Continue to next delivery';
  } else if (isEditingOrder || isOrderBundling) {
    return `Update ${deliveryMethodCopy}order`;
  } else {
    return `Place ${deliveryMethodCopy}order`;
  }
};
