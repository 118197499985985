import { ActionSheetContext, Toast, ToastContext } from '@alto/design-system';
import {
  type AddToCartParams,
  AncillaryItemsActionSheet,
  CompoundedMedicationActionSheet,
  MounjaroDosageSelectionActionSheet,
  OrderTypeActionSheet,
  useAddToCart,
  useRemoveFromCart,
} from '@alto/features';
import { ORDER_TYPE_CONTEXT } from '@alto/features';
import {
  type CheckoutFlowType,
  CheckoutFlowTypeMap,
} from '@alto/scriptdash/alto/patient_app/checkout_flow/types/v1/checkout_flow_type';
import {
  type AddToCartMetadata,
  type FetchAddToCartRequest,
  type FetchAddToCartResponse,
  type MultipleActiveRxPrescription,
} from '@alto/scriptdash/alto/patient_app/checkout_flow/v1/checkout_flow_endpoint';
import { type Prescription as HomescreenPrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/prescription';
import { type QueryFunctionContext, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { updateOrder } from '~shared/actions/cart';
import { closeModal, openModal } from '~shared/actions/modal';
import { selectPrescription } from '~shared/actions/prescriptions';
import { openCart } from '~shared/actions/ui/cart';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { prescriptionToItemKey } from '~shared/features/checkout/helpers';
import { getDefaultAddress } from '~shared/features/checkout/selectors/getDefaultAddress';
import { partitionMedicationsByActiveState } from '~shared/features/my-meds/selectors/partitionMedicationsByActiveState';
import { get } from '~shared/helpers/apiHelper';
import { getErrorMessage } from '~shared/helpers/helper';
import { useAnalytics, usePrevious } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { getIsCartShowing } from '~shared/selectors/ui/cart';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';
import { type APIError } from '~shared/types/APIError';
import { MultipleRxSelectionActionSheet } from '~web/features/my-meds/components/modals/MultipleRxSelectionActionSheet';

const validCheckoutFlowTypes = Object.values(CheckoutFlowTypeMap);
const isValidCheckoutFlowType = (type: CheckoutFlowType) => validCheckoutFlowTypes.includes(type);

const addToCartQueryKeys = {
  all: [{ $scope: 'add-to-cart' }] as const,
  prescription: ({
    addressID,
    prescriptionID,
    cartPrescriptionIDs,
  }: {
    addressID: number | null | undefined;
    prescriptionID: number | null;
    cartPrescriptionIDs: number[] | null | undefined;
  }) => [{ ...addToCartQueryKeys.all[0], addressID, prescriptionID, cartPrescriptionIDs }] as const,
};

type QueryData = {
  actionSheetFlowData: AddToCartMetadata | null | undefined;
  actionSheetFlowTypes: CheckoutFlowType[];
};

const fetchAddToCart = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof addToCartQueryKeys)['prescription']>>) => {
  const [{ addressID, prescriptionID, cartPrescriptionIDs }] = queryKey;
  const params: FetchAddToCartRequest = {
    address_id: addressID,

    // this query is disabled if prescriptionID is missing
    prescription_id: prescriptionID ?? 0,
    cart_prescription_ids: cartPrescriptionIDs,
  };
  const response: FetchAddToCartResponse & { error: Record<string, any> } = await get(
    '/checkout_flow/add_to_cart',
    params,
    { version: 'v1', splitArrayParams: true },
  );

  if (response.error) throw new Error(response.error.message);

  const actionSheetFlowTypes = (response?.data?.checkout_flow_types ?? []).filter(isValidCheckoutFlowType);

  return {
    actionSheetFlowData: response?.data?.checkout_flow_data,
    actionSheetFlowTypes,
  };
};

/**
 * We need to store the selected prescriptionID in state for the following:
 * - if the selected prescription is in the cart
 * - the query for fetching `add_to_cart` so we know if we're fetching or not
 */
const useSelectedPrescription = ({
  addressID,
  initialPrescriptionID,
  cartPrescriptionIDs,
}: {
  addressID: number | undefined;
  initialPrescriptionID: number;
  cartPrescriptionIDs: number[] | null | undefined;
}) => {
  const [prescriptionID, setPrescriptionID] = useState<number>(initialPrescriptionID);
  const { isFetching } = useQuery({
    enabled: false,
    queryKey: addToCartQueryKeys.prescription({ addressID, prescriptionID, cartPrescriptionIDs }),
    queryFn: fetchAddToCart,
  });
  const { isPrescriptionInCart } = usePrescriptionsInCart();

  return {
    isFetching,
    isPrescriptionInCart: isPrescriptionInCart(prescriptionID),
    setPrescriptionID,
  };
};

/**
 * This hook is responsible for showing the interstitial action sheets _before_ adding a prescription to the cart. There
 * should only be one instance of this hook at the top level to control the necessary action sheets that the patient
 * needs to see before adding a prescription to the cart.
 *
 * ! ⚠️ Mounjaro dose prompt and multiple active Rx sheets are required to be at the beginning.
 * This is because these flows can potentially change which prescription the patient wants to add to their cart, which
 * may affect future flows (not auto refill eligible, for example). adding flows before these can be tricky if it
 * applies to only some prescriptions and is currently not handled well on the client. for example, if rx 1 has flows
 * [multiple_active_rx, auto_refill] and rx 2 has flows [your_new_flow, multiple_active_rx, auto_refill], and the
 * patient selects rx 2 in the multiple_active_rx flow, the client may behave in strange ways.
 */
export const useAddToCartInterstitialSheets = ({
  origin,
  prescription,
  selectedAutoRefillStatus = undefined,
}: {
  origin: typeof ORIGIN_NAMES.HOMESCREEN | typeof ORIGIN_NAMES.MED_LIST | typeof ORIGIN_NAMES.MED_DETAILS;
  prescription: Omit<HomescreenPrescription & Prescription, 'dea_schedule'>;
  /**
   * This is only here to keep useAddToCartInterstitialSheets' interface the same between native and web.
   * On web, there isn't a way for the patient to select the auto refill status before adding to cart on MedDetails.
   */
  selectedAutoRefillStatus?: boolean | null | undefined;
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const { closeActionSheet, setActiveActionSheet } = useContext(ActionSheetContext);
  const address = useSelectorShared(getDefaultAddress);
  const addressID = address?.id;
  const [showFirstActionSheet, setShowFirstActionSheet] = useState(false);
  const previousShowFirstActionSheet = usePrevious(showFirstActionSheet);
  const initialPrescriptionID = prescription.id;
  const { handleRemoveFromCart } = useRemoveFromCart();
  const { active: activeMeds } = useSelectorShared(partitionMedicationsByActiveState);
  const isCartShowing = useSelectorShared(getIsCartShowing);
  const { prescriptionIDs: cartPrescriptionIDs } = usePrescriptionsInCart();
  const { handleAddToCart: addToCart, isPending: isAddToCartLoading } = useAddToCart();
  const { addToast } = useContext(ToastContext);
  const currentSheetRef = useRef<number>(0);
  const hasAddedToCartRef = useRef(false);

  const { isFetching, isPrescriptionInCart, setPrescriptionID } = useSelectedPrescription({
    addressID,
    initialPrescriptionID,
    cartPrescriptionIDs,
  });

  const initializeActionSheetsToShow = useCallback(async () => {
    if (!initialPrescriptionID) return;

    if (isPrescriptionInCart) {
      handleRemoveFromCart({ item: prescriptionToItemKey(prescription.id) });
      return;
    }

    dispatch(updateOrder({ origin }));
    trackEvent({
      event: EVENTS.ADD_TO_CART_TAPPED,
      additionalFields: { prescriptionId: initialPrescriptionID },
      params: {
        origin,
        price: prescription.price?.toString() || 'price not shown',
      },
    });

    // reset the action sheet index to the first sheet
    currentSheetRef.current = 0;

    const cachedData = queryClient.getQueryData<QueryData>(
      addToCartQueryKeys.prescription({
        addressID,
        prescriptionID: initialPrescriptionID,
        cartPrescriptionIDs,
      }),
    );

    // this is for any subsequent adds to cart and the
    // `actionSheetFlowTypes` should already be in the cache
    if (cachedData) {
      setShowFirstActionSheet(true);
      return;
    }

    try {
      const data = await queryClient.fetchQuery({
        queryKey: addToCartQueryKeys.prescription({
          addressID,
          prescriptionID: initialPrescriptionID,
          cartPrescriptionIDs,
        }),
        queryFn: fetchAddToCart,
      });

      if (data.actionSheetFlowData?.multiple_active_rx?.prescriptions) {
        const otherPrescriptions = data.actionSheetFlowData.multiple_active_rx.prescriptions.filter(({ id }) => {
          if (id === initialPrescriptionID) return false;

          const cachedPrescription = queryClient.getQueryData<QueryData>(
            addToCartQueryKeys.prescription({
              addressID,
              prescriptionID: id,
              cartPrescriptionIDs,
            }),
          );

          return !cachedPrescription;
        });
        const otherPrescriptionIDs = otherPrescriptions.map(({ id }) => id);

        // we wanna prefetch the query for the other prescriptions for the multi
        // rx case so they're already in the cache when we need to use them
        await Promise.allSettled(
          otherPrescriptionIDs.map((otherPrescriptionID) => {
            return queryClient.prefetchQuery({
              queryKey: addToCartQueryKeys.prescription({
                addressID,
                prescriptionID: otherPrescriptionID,
                cartPrescriptionIDs,
              }),
              queryFn: fetchAddToCart,
            });
          }),
        );
      }

      // fetch `add_to_cart` data all the other prescriptions for the mounjaro case
      if (data.actionSheetFlowData?.mounjaro_dose?.prescriptions) {
        const allPrescriptionIDs = data.actionSheetFlowData.mounjaro_dose.prescriptions.map(({ prescriptions }) => {
          return prescriptions.map(({ id }) => id);
        });
        const otherPrescriptionIDs = allPrescriptionIDs.flat().filter((id) => {
          if (id === initialPrescriptionID) return false;

          const cachedPrescription = queryClient.getQueryData<QueryData>(
            addToCartQueryKeys.prescription({
              addressID,
              prescriptionID: id,
              cartPrescriptionIDs,
            }),
          );

          return !cachedPrescription;
        });

        // we wanna prefetch the query for the other prescriptions for the mounjaro
        // case so they're already in the cache when we need to use them
        await Promise.allSettled(
          otherPrescriptionIDs.map((otherPrescriptionID) => {
            return queryClient.prefetchQuery({
              queryKey: addToCartQueryKeys.prescription({
                addressID,
                prescriptionID: otherPrescriptionID,
                cartPrescriptionIDs,
              }),
              queryFn: fetchAddToCart,
            });
          }),
        );
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err as APIError);

      addToast(
        <Toast
          variant="error"
          duration={3000}
        >
          {errorMessage}
        </Toast>,
      );
      return;
    }

    setShowFirstActionSheet(true);
    // eslint-disable-next-line sonarjs/no-redundant-jump
    return;
  }, [
    initialPrescriptionID,
    isPrescriptionInCart,
    dispatch,
    origin,
    trackEvent,
    prescription.price,
    prescription.id,
    queryClient,
    addressID,
    cartPrescriptionIDs,
    handleRemoveFromCart,
    addToast,
  ]);

  /**
   * Show the next action sheet in the flow using the `currentSheetRef` to keep track of the current action sheet.
   * We're always passing a `selectedPrescriptionID` to the next action sheet because we have the multi Rx and Mounjaro
   * dosage selection that could change the selected prescription that we want to add to the cart.
   */
  const showNextActionSheet = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    ({ selectedPrescriptionID }: { selectedPrescriptionID: number }) => {
      if (!selectedPrescriptionID) return;

      setShowFirstActionSheet(false);

      const handleAddToCart = async () => {
        const params: AddToCartParams = {
          resource_id: selectedPrescriptionID,
          resource_type: 'Prescription',
          auto_refill_enabled: selectedAutoRefillStatus,
        };
        const res = await addToCart(params);
        if (!res?.success) return;
        closeActionSheet();
        dispatch(closeModal('MOUNJARO_DIAGNOSIS_MODAL'));

        if (!isCartShowing) {
          dispatch(openCart());
        }
      };

      const cachedData = queryClient.getQueryData<QueryData>(
        addToCartQueryKeys.prescription({
          addressID,
          prescriptionID: selectedPrescriptionID,
          cartPrescriptionIDs,
        }),
      );
      let actionSheetFlowTypes;
      if (origin !== 'med details') {
        actionSheetFlowTypes = cachedData?.actionSheetFlowTypes ?? [];
      } else {
        actionSheetFlowTypes = (cachedData?.actionSheetFlowTypes ?? []).filter((sheetName: CheckoutFlowType) => {
          return sheetName !== 'multiple_active_rx';
        });
      }

      // if we've run through all the necessary action sheets, then add to cart
      if (actionSheetFlowTypes.length < 1 || currentSheetRef.current >= actionSheetFlowTypes.length) {
        if (!hasAddedToCartRef.current) handleAddToCart();
        return;
      }

      const sheetName = actionSheetFlowTypes[currentSheetRef.current];

      if (sheetName === 'ancillary') {
        if (actionSheetFlowTypes.length === 1) {
          handleAddToCart();
          hasAddedToCartRef.current = true;
        }

        currentSheetRef.current += 1;

        if (origin !== 'med details') {
          setActiveActionSheet(
            <AncillaryItemsActionSheet
              prescriptionID={selectedPrescriptionID}
              onBack={() => {
                currentSheetRef.current -= 1;
              }}
              onPress={() => {
                if (hasAddedToCartRef.current) closeActionSheet();
                showNextActionSheet({ selectedPrescriptionID });
              }}
            />,
          );
        } else {
          showNextActionSheet({ selectedPrescriptionID });
        }
      }

      if (sheetName === 'compounded') {
        if (actionSheetFlowTypes.length === 1) {
          handleAddToCart();
          hasAddedToCartRef.current = true;
        }

        currentSheetRef.current += 1;

        if (origin !== 'med details') {
          const medication = activeMeds.find((m) => m.prescriptions.some((p) => p.id === selectedPrescriptionID));
          const medicationName = medication?.medicationName ?? 'The medication';
          setActiveActionSheet(
            <CompoundedMedicationActionSheet
              medicationName={medicationName}
              onBack={() => {
                currentSheetRef.current -= 1;
              }}
              onPress={() => {
                if (hasAddedToCartRef.current) closeActionSheet();
                showNextActionSheet({ selectedPrescriptionID });
              }}
            />,
          );
        } else {
          showNextActionSheet({ selectedPrescriptionID });
        }
      }

      if (sheetName === 'mounjaro_dose') {
        const medication = activeMeds.find((m) => m.prescriptions.some((p) => p.id === selectedPrescriptionID));

        const prescriptionsForNdc = cachedData?.actionSheetFlowData?.mounjaro_dose?.prescriptions ?? [];
        const selectedPrescription = medication?.prescriptions?.find(({ id }) => id === selectedPrescriptionID);

        setActiveActionSheet(
          <MounjaroDosageSelectionActionSheet
            prescriptionsForNdc={prescriptionsForNdc}
            selectedPrescription={selectedPrescription}
            addToCart={(prescription) => {
              setPrescriptionID(prescription.id);
              showNextActionSheet({ selectedPrescriptionID: prescription.id });
            }}
            origin={origin}
          />,
        );

        currentSheetRef.current += 1;
      }

      if (sheetName === 'multiple_active_rx') {
        const medication = activeMeds.find((m) => m.prescriptions.some((p) => p.id === selectedPrescriptionID));
        // If we don't find an active medication that matches the selected prescription, then we can
        // just move on to the next action sheet in the flow.
        //
        // This can happen if the patient has an existing prescription that needs attention or needs
        // some action to be done by the patient – either due for refill or due for renewal. In this
        // case, we can skip doing the multiple Rx selection because one of those Rxs would be
        // inactive or requires patient action.
        if (!medication) {
          currentSheetRef.current += 1;
          showNextActionSheet({ selectedPrescriptionID });
          return;
        }

        const prescriptions = cachedData?.actionSheetFlowData?.multiple_active_rx?.prescriptions ?? [];

        setActiveActionSheet(
          <MultipleRxSelectionActionSheet
            hasSheetsLeft={actionSheetFlowTypes.length - currentSheetRef.current > 1}
            onPrescriptionSelect={(p: MultipleActiveRxPrescription) => {
              setPrescriptionID(p.id);
              showNextActionSheet({ selectedPrescriptionID: p.id });
            }}
            prescriptions={prescriptions || []}
          />,
        );

        currentSheetRef.current += 1;
      }

      if (sheetName === 'diabetes_election') {
        dispatch(
          openModal('MOUNJARO_DIAGNOSIS_MODAL', {
            onSave: () => {
              showNextActionSheet({ selectedPrescriptionID });
            },
          }),
        );

        currentSheetRef.current += 1;
      }

      // this should be the last action sheet in the flow, and it handles adding the prescription to the cart
      if (sheetName === 'auto_refill_eligible') {
        dispatch(selectPrescription(selectedPrescriptionID));

        setActiveActionSheet(
          <OrderTypeActionSheet
            prescriptionID={selectedPrescriptionID}
            initialAutoRefillEnabledValue={selectedAutoRefillStatus}
            orderTypeContext={ORDER_TYPE_CONTEXT.ADD_TO_CART}
            handleBack={() => {
              currentSheetRef.current -= 1;
            }}
          />,
        );

        currentSheetRef.current += 1;
      }
    },
    [
      activeMeds,
      addressID,
      addToCart,
      cartPrescriptionIDs,
      closeActionSheet,
      dispatch,
      isCartShowing,
      origin,
      queryClient,
      selectedAutoRefillStatus,
      setActiveActionSheet,
      setPrescriptionID,
    ],
  );

  /**
   * This handles showing the next action sheet in the flow after we've
   * already fetched the `actionSheetFlowTypes` and it's in the cache.
   * ! ⚠️ i would like to not have to use an effect here
   */
  useEffect(() => {
    if (!previousShowFirstActionSheet && showFirstActionSheet && prescription.id) {
      showNextActionSheet({ selectedPrescriptionID: prescription.id });
    }
  }, [prescription.id, previousShowFirstActionSheet, showFirstActionSheet, showNextActionSheet]);

  return {
    initializeActionSheetsToShow,
    isLoading: isAddToCartLoading || isFetching,
    isPrescriptionInCart,
  };
};
