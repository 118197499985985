// @owners { team: patients-team }
import { ActionSheetContext, Button, ButtonSideCopy } from '@alto/design-system';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import { useIsMutating } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { getSaveOrderLoading } from '~shared/features/checkout/selectors/getOrder';
import { type AnalyticsProps } from '~shared/features/essentials/AnalyticsProps';
import { updateAddToShipmentError } from '~shared/features/essentials/actions';
import { type OriginValues } from '~shared/features/essentials/add_ons_consts';
import { useEssentialPrescriptionByProductID } from '~shared/features/essentials/hooks';
import { formatDollars } from '~shared/helpers/currency';
import { getErrorMessageFromResponse } from '~shared/helpers/helper';
import { useAddOnsAddToCart } from '~shared/queries/useAddOnsAddToCart';
import { useAddOnsAddToShipment } from '~shared/queries/useAddOnsAddToShipment';
import { useAddOnsDeletePrescription } from '~shared/queries/useAddOnsDeletePrescription';
import { useAddOnsUpdatePrescription } from '~shared/queries/useAddOnsUpdatePrescription';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useAddOnsContext } from '../../checkout/components/essentials/AddOnsContext';
import { CREATE_ORDER_MUTATION_KEY } from '../../checkout/queries/useCreateOrder';
import { RelatedEssentialsActionSheet } from './RelatedEssentialsActionSheet';

type ButtonPriceProps = {
  readonly price: number;
  readonly itemQuantity: number;
};

const ButtonPrice = ({ itemQuantity, price }: ButtonPriceProps) => {
  return itemQuantity ? <ButtonSideCopy>{formatDollars(itemQuantity * price)}</ButtonSideCopy> : null;
};

type ButtonProps = {
  readonly onClose: () => void;
  readonly itemQuantity: number;
  readonly essential: AddOnOtc;
  readonly analyticsProps: AnalyticsProps;
  readonly disabled?: boolean;
  readonly onDelete?: () => void;
};

export const EssentialsUpdateButton = ({
  onClose,
  itemQuantity,
  essential,
  analyticsProps,
  disabled,
  onDelete,
}: ButtonProps) => {
  const dispatch = useDispatchShared();
  const { shipmentID, onAdd, onRemove, onUpdateQuantity } = useAddOnsContext();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { essential: prescription } = useEssentialPrescriptionByProductID({
    productID: essential.product_id,
    shipmentID,
  });
  const isCreateOrderPending = !!useIsMutating({ mutationKey: CREATE_ORDER_MUTATION_KEY });
  const saveOrderLoading = useSelectorShared(getSaveOrderLoading);

  const { mutateAsync: addToCart, isPending: addToCartLoading } = useAddOnsAddToCart();
  const { mutateAsync: addToShipment, isPending: addToShipmentLoading } = useAddOnsAddToShipment();
  const { mutateAsync: updateQuantity, isPending: updateQuantityLoading } = useAddOnsUpdatePrescription();
  const { mutateAsync: deletePrescription, isPending: deletePrescriptionLoading } = useAddOnsDeletePrescription();
  const isLoading =
    addToCartLoading || addToShipmentLoading || updateQuantityLoading || saveOrderLoading || isCreateOrderPending;
  const targetObject = shipmentID ? 'order' : 'cart';

  const handleRelatedEssentials = (origin?: OriginValues): void => {
    setActiveActionSheet(
      <RelatedEssentialsActionSheet
        shipmentID={shipmentID}
        essential={essential}
        origin={origin}
      />,
    );
  };

  const handleAdd = async () => {
    if (itemQuantity === 0) {
      onClose();
      return;
    }

    const addToOrder = await onAdd(
      essential,
      shipmentID ? addToShipment : addToCart,
      onClose,
      itemQuantity,
      analyticsProps,
      handleRelatedEssentials,
    );

    if (addToOrder?.error) {
      const error = getErrorMessageFromResponse(addToOrder);
      dispatch(updateAddToShipmentError({ error, quantityAdded: itemQuantity }));
    }
  };

  const handleUpdate = async () => {
    if (!prescription) return;
    if (itemQuantity > 0) {
      await onUpdateQuantity(essential, prescription, updateQuantity, itemQuantity, analyticsProps);
      onClose();
    } else {
      await onRemove(essential, prescription, deletePrescription, analyticsProps);

      if (onDelete) {
        onDelete();
      } else {
        onClose();
      }
    }
  };

  if (prescription) {
    return (
      <Button
        label={itemQuantity > 0 ? `Update ${targetObject}` : `Remove from ${targetObject}`}
        spaceBetween={itemQuantity !== 0}
        RightContent={
          disabled ? undefined : (
            <ButtonPrice
              price={essential.price}
              itemQuantity={itemQuantity}
            />
          )
        }
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPress={handleUpdate}
        disabled={disabled || deletePrescriptionLoading}
        loading={updateQuantityLoading}
      />
    );
  }

  return (
    <Button
      label={`Add to ${targetObject}`}
      spaceBetween
      RightContent={
        disabled ? undefined : (
          <ButtonPrice
            price={essential.price}
            itemQuantity={itemQuantity}
          />
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onPress={handleAdd}
      disabled={disabled || deletePrescriptionLoading}
      loading={isLoading}
    />
  );
};
