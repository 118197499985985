// @owners { team: patients-team }
import { Experimentation } from '@alto/experimentation';
import { SHIPMENT_STATUS_MAP, SHIPMENT_STATUS_MAP_V2 } from '~shared/features/shipments/constants';

export const useShipmentStatusMap = () => {
  const { value: useV2 } = Experimentation.useFeatureFlag('pickup_ready_at_package');
  if (useV2) {
    return SHIPMENT_STATUS_MAP_V2;
  } else {
    return SHIPMENT_STATUS_MAP;
  }
};
