// @owners { team: patients-team }
import { ActionSheetContext, Button, Column } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import {
  type MedDetailsPrescriptionCTA,
  type MedDetailsPrescriptionCTAParams,
  type MedDetailsPrescriptionCTAs,
} from '@alto/scriptdash/alto/medications/patients/v1/types';
import React, { useCallback, useContext } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { prescriptionToItemKey } from '~shared/features/checkout/helpers';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { PRESCRIPTION_PRICING_LABELS } from '~shared/features/pricing/constants';
import getPrescriptionPricingByID from '~shared/features/pricing/selectors/getPrescriptionPricingByID';
import { getItemPricingTotal } from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import { PrescriptionStatusTracker } from '../../../alto-assistant';
import { OrderTypeActionSheet } from '../../../auto-refill';
import { useRemoveFromCart } from '../../../cart';
import { RenewalCancelActionSheet } from '../../../med-list/components/RenewalCancelActionSheet';
import { RenewalRequestStatusActionSheet } from '../../../med-list/components/RenewalRequestStatusActionSheet';
import { RenewalRequestActionSheet } from '../../../my-meds';
import { DiscontinueActionSheet } from '../DiscontinueActionSheet';
import { TurnOffAutoRefillActionSheet } from '../TurnOffAutoRefillActionSheet';

type Props = {
  readonly primaryPrescriptionId: number;
  readonly prescriptionId: number;
  readonly ctas: MedDetailsPrescriptionCTAs;
  readonly useAddToCartInterstitialSheets: any;
  readonly medicationName: string;
};

export const CTAs = ({
  prescriptionId,
  ctas,
  useAddToCartInterstitialSheets,
  medicationName,
  primaryPrescriptionId,
}: Props) => {
  const isWeb = getPlatformOS() === 'web';
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { closeActionSheet, setActiveActionSheet } = useContext(ActionSheetContext);
  const { isPrescriptionInCart } = usePrescriptionsInCart();
  const { handleRemoveFromCart } = useRemoveFromCart();

  const reduxPrescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionId));
  const prescriptionPricing = useSelectorShared((state) => getPrescriptionPricingByID(state, prescriptionId));
  const isProgyny = getItemPricingTotal(prescriptionPricing).reason === PRESCRIPTION_PRICING_LABELS.PROGYNY;
  const isProgynyWeb = isWeb && isProgyny;
  const inCart = prescriptionId ? isPrescriptionInCart(prescriptionId) : false;
  const { primary, secondary, tertiary } = ctas;

  const { initializeActionSheetsToShow, isDisabled } = useAddToCartInterstitialSheets({
    origin: ORIGIN_NAMES.MED_DETAILS,
    prescription: reduxPrescription,
  });

  const showAddToCartActionSheet = useCallback(() => {
    trackEvent({
      event: EVENTS.ADD_TO_CART_TAPPED,
      additionalFields: { prescriptionId },
      params: { origin: ORIGIN_NAMES.MED_DETAILS },
    });
    initializeActionSheetsToShow();
  }, [initializeActionSheetsToShow, prescriptionId, trackEvent]);

  const handleViewOrder = useCallback(
    (params: MedDetailsPrescriptionCTAParams | null | undefined) => {
      if (params?.shipment_id) {
        navigate('RouteOrderDetails', {
          shipmentID: params.shipment_id,
        });
      }
    },
    [navigate],
  );

  const handleViewRefillRequest = useCallback(() => {
    if (!reduxPrescription) return;
    setActiveActionSheet(<RenewalRequestStatusActionSheet prescription={reduxPrescription} />);
  }, [setActiveActionSheet, reduxPrescription]);

  const handleRequestMoreRefills = useCallback(() => {
    setActiveActionSheet(<RenewalRequestActionSheet prescriptionIDs={[prescriptionId]} />);
  }, [prescriptionId, setActiveActionSheet]);

  const handleCheckStatus = useCallback(() => {
    setActiveActionSheet(
      <PrescriptionStatusTracker
        origin="MedDetails"
        prescriptionId={prescriptionId}
        prescriptionName={reduxPrescription?.medication_name || 'Your medication'}
        prescriptionStatus={reduxPrescription?.status}
      />,
    );
  }, [reduxPrescription?.medication_name, reduxPrescription?.status, prescriptionId, setActiveActionSheet]);

  const handlePrimaryPress = useCallback(() => {
    switch (primary?.type) {
      case 'VIEW_ORDER':
        handleViewOrder(primary?.params);
        return;
      case 'REQUEST_MORE_REFILLS':
        handleRequestMoreRefills();
        return;
      case 'VIEW_REFILL_REQUEST':
        handleViewRefillRequest();
        return;
      case 'CHECK_STATUS':
        handleCheckStatus();
        return;
      case 'ADD_TO_CART':
        showAddToCartActionSheet();
        return;
      case 'NONE':
      default:
        return;
    }
  }, [
    primary,
    handleViewOrder,
    handleRequestMoreRefills,
    handleViewRefillRequest,
    handleCheckStatus,
    showAddToCartActionSheet,
  ]);

  const handleCancelRefillRequest = useCallback(() => {
    if (reduxPrescription) {
      setActiveActionSheet(<RenewalCancelActionSheet prescription={reduxPrescription} />);
    }
  }, [setActiveActionSheet, reduxPrescription]);

  const handleOrderEarly = useCallback(() => {
    navigate('RouteOrderEarlyForm', { prescriptionId });
  }, [navigate, prescriptionId]);

  const handleSecondaryPress = useCallback(() => {
    switch (secondary?.type) {
      case 'CANCEL_REFILL_REQUEST':
        handleCancelRefillRequest();
        return;
      case 'REMOVE_FROM_CART':
        handleRemoveFromCart({ item: prescriptionToItemKey(prescriptionId) });
        return;
      case 'PROGYNY_ORDER_EARLY':
        handleOrderEarly();
        return;
      case 'NONE':
      default:
        return;
    }
  }, [secondary?.type, handleCancelRefillRequest, handleRemoveFromCart, handleOrderEarly, prescriptionId]);

  const handleEnrollInAutoRefill = useCallback(() => {
    setActiveActionSheet(
      <OrderTypeActionSheet
        handleBack={closeActionSheet}
        initialAutoRefillEnabledValue
        prescriptionID={prescriptionId}
        orderTypeContext="Post checkout"
      />,
    );
  }, [closeActionSheet, prescriptionId, setActiveActionSheet]);

  const handleTurnOffAutoRefill = useCallback(() => {
    setActiveActionSheet(<TurnOffAutoRefillActionSheet prescriptionID={prescriptionId} />);
  }, [prescriptionId, setActiveActionSheet]);

  const handleDiscontinuePrescription = useCallback(() => {
    setActiveActionSheet(
      <DiscontinueActionSheet
        medicationName={medicationName}
        prescriptionId={prescriptionId}
        primaryPrescriptionId={primaryPrescriptionId}
      />,
    );
  }, [medicationName, prescriptionId, primaryPrescriptionId, setActiveActionSheet]);

  const handleTertiaryPress = useCallback(() => {
    switch (tertiary?.type) {
      case 'ENROLL_IN_AUTO_REFILL':
        handleEnrollInAutoRefill();
        return;
      case 'TURN_OFF_AUTO_REFILL':
        handleTurnOffAutoRefill();
        return;
      case 'DISCONTINUE':
        handleDiscontinuePrescription();
        return;
      case 'NONE':
      default:
        return;
    }
  }, [tertiary?.type, handleEnrollInAutoRefill, handleTurnOffAutoRefill, handleDiscontinuePrescription]);

  const showCTA = useCallback(
    (cta: MedDetailsPrescriptionCTA) => {
      return (
        cta?.type &&
        cta.type !== 'NONE' &&
        !(cta.type === 'ADD_TO_CART' && inCart) &&
        !(cta.type === 'REMOVE_FROM_CART' && !inCart)
      );
    },
    [inCart],
  );

  return (
    <Column gap="XS">
      {ctas?.primary && showCTA(ctas.primary) ? (
        <Button
          disabled={isDisabled || isProgynyWeb}
          onPress={handlePrimaryPress}
        >
          {isProgynyWeb ? 'Order on the Alto app' : primary?.copy}
        </Button>
      ) : null}
      {ctas?.secondary && showCTA(ctas.secondary) ? (
        <Button
          disabled={isDisabled || (isWeb && secondary?.type === 'PROGYNY_ORDER_EARLY')}
          onPress={handleSecondaryPress}
          type="secondary"
        >
          {isWeb && secondary?.type === 'PROGYNY_ORDER_EARLY' ? 'Order early on the Alto app' : secondary?.copy}
        </Button>
      ) : null}
      {ctas?.tertiary && showCTA(ctas.tertiary) ? (
        <Button
          onPress={handleTertiaryPress}
          type="tertiary"
        >
          {tertiary?.copy}
        </Button>
      ) : null}
    </Column>
  );
};
