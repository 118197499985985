import { BORDERS, COLORS, SIZES, SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import { Animated, type ImageSourcePropType, type LayoutChangeEvent, StatusBar, View } from 'react-native';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { getPercentageOfScreenHeight } from '../../utils';
import { Column } from '../containers';
import { Illustration } from '../images';
import { BottomSheet, type BottomSheetProps } from './BottomSheet';

const MAX_HEIGHT_PERCENTAGE = 90;

const IllustrationAnchor = styled(View)<{ illustrationXl?: boolean }>`
  position: absolute;
  right: ${SPACING.STATIC.LG.px};
  top: -${({ illustrationXl }) => (illustrationXl ? SIZES.ILLUSTRATION.XL.value : SIZES.ILLUSTRATION.LG.value) / 2}px;
  z-index: 1;
`;

const StyledModal = styled(Modal)`
  justify-content: flex-end;
  margin: 0;
`;

const ActionSheetWrapper = styled(Column)`
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: ${BORDERS.RADIUS.LG.px};
  border-top-right-radius: ${BORDERS.RADIUS.LG.px};
  max-height: ${MAX_HEIGHT_PERCENTAGE}%;
`;

const StyledAnimatedView = styled(Animated.View)`
  flex-shrink: 1;
`;

export type ActionSheetProps = Omit<BottomSheetProps, 'handleClose' | 'handleBack' | 'isFirstSheet'> & {
  readonly fadeAnimationValue?: Animated.Value;
  /* an image that will appear at the top right of the action sheet, halfway overlapped above it. */
  readonly illustrationSrc?: ImageSourcePropType;
  /* used in conjunction with illustrationSrc, this makes the image ~50% larger than it would be otherwise */
  readonly illustrationXl?: boolean;
  readonly isVisible?: boolean;
  readonly onModalHide?: () => void;
  readonly handleModalClose: () => void;
  readonly handleBackClick?: () => void;
};

/**
 *
 * @deprecated please use ActionSheetV2 and setActiveActionSheet instead. See more details in the
 * readme here https://github.com/scriptdash/clients/tree/master/libs/design-system/src/components/action-sheet/README.md
 *
 */
export const DEPRECATED_ActionSheet = ({
  buttons,
  children,
  description,
  fadeAnimationValue,
  footnote,
  handleBackClick,
  handleModalClose,
  illustrationSrc,
  illustrationXl,
  isVisible,
  onModalHide,
  Tag,
  title,
}: ActionSheetProps) => {
  const [hasScrollableContents, setHasScrollableContents] = React.useState(false);
  const { top } = useSafeAreaInsets();

  // Calculate the height of the action sheet content to see if we should be able to scroll it
  const handleLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    const statusBarHeight = StatusBar.currentHeight || top;
    const maxHeight = getPercentageOfScreenHeight(MAX_HEIGHT_PERCENTAGE);

    if (height + statusBarHeight >= maxHeight) {
      setHasScrollableContents(true);
    }
  };

  return (
    <StyledModal
      animationInTiming={200}
      animationOutTiming={800}
      avoidKeyboard
      backdropColor={COLORS.BACKGROUND_COLORS.MODAL_BACKGROUND}
      backdropOpacity={0.75}
      isVisible={isVisible}
      onBackdropPress={handleModalClose}
      onModalHide={onModalHide}
      onSwipeComplete={handleModalClose}
      propagateSwipe
      swipeDirection="down"
    >
      <ActionSheetWrapper>
        {!!illustrationSrc && (
          <IllustrationAnchor illustrationXl={illustrationXl}>
            <Illustration
              size={illustrationXl ? 'xl' : 'lg'}
              source={illustrationSrc}
            />
          </IllustrationAnchor>
        )}
        <StyledAnimatedView
          onLayout={handleLayout}
          style={{ opacity: fadeAnimationValue }}
        >
          <BottomSheet
            buttons={buttons}
            description={description}
            footnote={footnote}
            handleBack={handleBackClick}
            handleClose={handleModalClose}
            hasScrollableContentsOverride={hasScrollableContents}
            Tag={Tag}
            title={title}
            isFirstSheet={false}
          >
            {children}
          </BottomSheet>
        </StyledAnimatedView>
      </ActionSheetWrapper>
    </StyledModal>
  );
};
