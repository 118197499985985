// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Button,
  FlatCard,
  LgPadding,
  LgSpacing,
  Toast,
  ToastContext,
} from '@alto/design-system';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
// eslint-disable-next-line import/no-deprecated
import { cancelDeliveries } from '~shared/actions/deliveries';
import { updateAutoRefill } from '~shared/actions/prescriptions';
import { getAddress } from '~shared/features/addresses/selectors/getAddress';
import { getNextDeliveryForPrescriptionID } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { getErrorMessage } from '~shared/helpers/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { homescreenMethods } from '../../homescreen';
import { CalendarRow, LocationRow } from '../../info-rows';
import { useInvalidateMedList } from '../../med-list/queries/useMedList';
import { useInvalidateMedDetails } from '../queries/useMedDetails';

type Props = {
  readonly prescriptionID: number;
};

export const TurnOffAutoRefillActionSheet = ({ prescriptionID }: Props) => {
  const dispatch = useDispatchShared();
  const { addToast } = useContext(ToastContext);
  const { closeActionSheet } = useContext(ActionSheetContext);
  const queryClient = useQueryClient();
  const { invalidateMedicationQuery, invalidatePrescriptionsQuery } = useInvalidateMedDetails();
  const { invalidateListQuery } = useInvalidateMedList();
  const delivery = useSelectorShared((state) => getNextDeliveryForPrescriptionID(state, { prescriptionID }));
  const userID = useSelectorShared(getCurrentUserID);
  const address = useSelectorShared((state) =>
    getAddress(state, {
      addressID: delivery?.address_id,
    }),
  );

  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  const updateAutoRefillError = useSelectorShared((state) => state.ui.errors.updateAutoRefillError);
  const cancelDeliveryError = useSelectorShared((state) => state.ui.errors.cancelDeliveriesError);

  const invalidateQueries = ({ isCancelingDelivery } = { isCancelingDelivery: false }) => {
    // invalidate CTAs for medication details and other prescriptions
    invalidateMedicationQuery(prescriptionID);
    invalidatePrescriptionsQuery(prescriptionID);
    if (isCancelingDelivery) {
      // invalidate homescreen so the relevant upcoming delivery is removed
      queryClient.invalidateQueries({ queryKey: homescreenMethods.fetchHomescreen({ userID }).queryKey });
      // invalidate med list so upcoming delivery copy is removed
      invalidateListQuery();
    }
  };

  const handleTurnOffAutoRefillKeepOrder = async () => {
    const autoRefillEnabled = false;
    const success = await dispatch(updateAutoRefill(prescriptionID, autoRefillEnabled));
    closeActionSheet();
    if (success) {
      invalidateQueries();
      addToast(<Toast variant="success">Auto refill turned off for this medication</Toast>);
    } else {
      addToast(<Toast variant="error">{getErrorMessage(updateAutoRefillError)}</Toast>);
    }
  };

  const handleTurnOffAutoRefillAndCancelOrder = async () => {
    if (!prescription || !delivery) return;
    const turnOffAutoRefill = true;

    const success = await dispatch(
      // eslint-disable-next-line import/no-deprecated
      cancelDeliveries([{ ...delivery, prescription }], 'other', 'turn off auto refill', turnOffAutoRefill),
    );
    closeActionSheet();
    if (success) {
      invalidateQueries({ isCancelingDelivery: true });
      addToast(<Toast>Delivery canceled and auto refill turned off for this medication.</Toast>);
    } else {
      addToast(<Toast variant="error">{getErrorMessage(cancelDeliveryError)}</Toast>);
    }
  };

  return (
    <ActionSheetV2
      title="Turn off auto refill"
      description="We will no longer automatically send future orders. You can turn it back on at any time."
      buttons={[
        <Button
          key="turn-off-auto-refill-keep-order"
          label="Turn off and keep order"
          type="secondary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handleTurnOffAutoRefillKeepOrder}
        />,
        <Button
          key="turn-off-auto-refill-cancel-order"
          label="Turn off and cancel order"
          type="secondary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={handleTurnOffAutoRefillAndCancelOrder}
        />,
        <Button
          key="turn-off-auto-refill-cancel"
          label="Cancel"
          type="tertiary"
          onPress={closeActionSheet}
        />,
      ]}
    >
      {delivery ? (
        <LgPadding
          topPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.NONE}
        >
          <FlatCard title="Upcoming delivery">
            <CalendarRow
              date={delivery.date}
              startTime={delivery.deliver_after}
              endTime={delivery.deliver_before}
            />
            <LocationRow
              address={address}
              isPickup={delivery.is_pickup ?? false}
              showMap={delivery.status !== 'delivered'}
            />
            <LgSpacing />
          </FlatCard>
        </LgPadding>
      ) : null}
    </ActionSheetV2>
  );
};
