// @owners { team: patients-team }
import { AltoIcon, LgSpacing, Tag, XxsSpacing } from '@alto/design-system';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React, { memo } from 'react';

export const FSABadge = memo(function FSABadgeMemo({ addOnOtc }: { readonly addOnOtc: AddOnOtc }) {
  if (addOnOtc.fsa_hsa_eligible) {
    return (
      <Tag
        label="FSA"
        icon={<AltoIcon name="checkcircle-small" />}
        type="recommended"
      />
    );
  }

  return (
    <>
      <LgSpacing />
      <XxsSpacing />
    </>
  );
});
