import { type StatusMap } from '~shared/features/shipments/constants';
import { type ShipmentMethod, type ShipmentStatus } from '~shared/types';

export const getShipmentStatusString = (
  status: ShipmentStatus | undefined,
  shipmentStatusMap: StatusMap,
  isPickup = false,
): string | undefined => {
  if (!status) {
    return '';
  }
  if (isPickup) {
    return shipmentStatusMap[status]?.pickupStatusString;
  }
  return shipmentStatusMap[status]?.statusString;
};

export const getShipmentStatusInfoText = (
  status: ShipmentStatus | undefined,
  method: ShipmentMethod | undefined,
  shipmentStatusMap: StatusMap,
) => {
  if (!status || !shipmentStatusMap[status]) {
    return '';
  }

  const config = shipmentStatusMap[status];

  if (!method) {
    return config?.infoText;
  }

  if (method.includes('fedex') || method.includes('usps')) {
    return config?.mailInfoText;
  }

  if (method.includes('pickup')) {
    return config?.pickupInfoText;
  }

  return config?.infoText;
};
