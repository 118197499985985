import { COLORS, SPACING } from '@alto/design-library-tokens';
import * as React from 'react';
import { type HeadlineButton, Pressable } from '../../buttons';
import { Column, MdPadding, Row } from '../../containers';
import { type AltoIcon } from '../../icon';
import { XsSpacing, XxsSpacing } from '../../separators';
import { type Tag } from '../../tags';
import { H2, Text } from '../../typography';

export type ListHeaderProps = {
  readonly title?: string;
  readonly description?: string;
  readonly Tag?: React.ReactElement<typeof Tag>;
  readonly onPress?: () => void;
  readonly Icon?: React.ReactElement<typeof AltoIcon>;
  readonly TitleCTA?: React.ReactElement<typeof HeadlineButton>;
  readonly fullBleed?: boolean;
};

const Header = ({ title, description, Tag, Icon, TitleCTA }: Omit<ListHeaderProps, 'onPress'>) => (
  <Column>
    <Row
      spaceBetween
      centerVertically
    >
      <Row
        centerVertically
        flexShrink={1}
      >
        <Column>
          {Tag ? (
            <>
              {Tag}
              <XsSpacing />
            </>
          ) : null}
          {!!title && (
            <H2
              contain
              color={COLORS.TEXT_COLORS.PRIMARY}
            >
              {title}
            </H2>
          )}
          {!!description && (
            <>
              <XxsSpacing />
              <Text
                textSize="mini"
                tight
                color={COLORS.TEXT_COLORS.GREY}
              >
                {description}
              </Text>
            </>
          )}
        </Column>
      </Row>
      {!!Icon && <Row centerVertically>{Icon}</Row>}
      {!!TitleCTA && TitleCTA}
    </Row>
  </Column>
);

const HeaderContainer = ({ title, description, Tag, Icon, TitleCTA, fullBleed }: Omit<ListHeaderProps, 'onPress'>) => {
  return fullBleed ? (
    <Header
      title={title}
      description={description}
      Tag={Tag}
      Icon={Icon}
      TitleCTA={TitleCTA}
    />
  ) : (
    <MdPadding
      leftPadding={SPACING.STATIC.LG}
      rightPadding={SPACING.STATIC.LG}
    >
      <Header
        title={title}
        description={description}
        Tag={Tag}
        Icon={Icon}
        TitleCTA={TitleCTA}
      />
    </MdPadding>
  );
};

export const ListHeader = ({ title, description, Tag, onPress, Icon, TitleCTA, fullBleed }: ListHeaderProps) => {
  return onPress ? (
    <Pressable
      pressedBackgroundColor={COLORS.BACKGROUND_COLORS.PRIMARY_LIGHTEST}
      onPress={onPress}
      opacity={false}
      testID="pressable"
    >
      <HeaderContainer
        title={title}
        description={description}
        Tag={Tag}
        Icon={Icon}
        TitleCTA={TitleCTA}
        fullBleed={fullBleed}
      />
    </Pressable>
  ) : (
    <HeaderContainer
      title={title}
      description={description}
      Tag={Tag}
      Icon={Icon}
      TitleCTA={TitleCTA}
      fullBleed={fullBleed}
    />
  );
};
