import { NavigationService } from '@alto/navigation';
import { type ASSISTANT_CHAT_ORIGINS, type ASSISTANT_PAGE_ORIGINS } from '~shared/features/alto-assistant/constants';
import { isNative } from '~shared/helpers/isNative';

export const ORIGIN_NAMES = Object.freeze({
  ADD_MED_BUTTON: 'Add Med Button',
  AUTO_REFILL_MANAGEMENT: 'Auto Refill Management',
  CART: 'cart',
  CHECKOUT: 'checkout',
  DELIVERIES: 'deliveries',
  DELIVERY_DETAILS: 'delivery details',
  EDIT_DELIVERY: 'edit delivery',
  FAMILY_MEMBER_ADDED: 'family member added',
  FAQ: 'faq',
  HOMESCREEN: 'Homescreen',
  HOMESCREEN_RENEWALS: 'Homescreen Renewals',
  LOGIN: 'login',
  MED_LIST: 'med list',
  MED_DETAILS: 'med details',
  MESSAGES: 'messages',
  NUDGE_CARD: 'Nudge Card',
  ONBOARDING: 'onboarding',
  ORDER_BUNDLING: 'Order Bundling',
  ORDER_DATE_PICKER: 'Order Date Picker',
  ORDER_TYPE: 'OrderType',
  PROFILE: 'profile',
  RX_TRACKER: 'Prescription Tracker',
  SCHEDULE_DELIVERY: 'Schedule Delivery',
  SEND_PRESCRIPTION_ACTION_CARD: 'Send Prescription Action Card',
  TRANSFER: 'transfer',
  TRANSFER_UPSELL: 'transferUpsell',
  TRANSFER_PHOTO: 'Transfer Photo',
  TRANSFER_PREFILLED: 'transfer prefilled',
  TRANSFER_PRESCRIPTION: 'Transfer Prescription',
  MULTIPLE_ACTIVE_RX_SAME_AVAILABILITY_DATE: 'Multiple active rx same availability date',
  MOUNJARO_DOSAGE_SELECTION: 'Mounjaro dosage selection',
} as const);

export type TransferFormOrigin =
  | typeof ORIGIN_NAMES.TRANSFER
  | typeof ORIGIN_NAMES.TRANSFER_UPSELL
  | typeof ORIGIN_NAMES.ONBOARDING
  | typeof ORIGIN_NAMES.TRANSFER_PRESCRIPTION
  | typeof ORIGIN_NAMES.FAMILY_MEMBER_ADDED;

export const TRANSFER_ORIGINS = {
  postCheckout: 'post-checkout',
  postRateShipment: 'post-rate-shipment',
  postRenewal: 'post-renewal',
} as const;

export type TransferOrigin = (typeof TRANSFER_ORIGINS)[keyof typeof TRANSFER_ORIGINS];

export type OriginName =
  | (typeof ORIGIN_NAMES)[keyof typeof ORIGIN_NAMES]
  | (typeof ASSISTANT_PAGE_ORIGINS)[keyof typeof ASSISTANT_PAGE_ORIGINS]
  | (typeof ASSISTANT_CHAT_ORIGINS)[keyof typeof ASSISTANT_CHAT_ORIGINS];

const MOBILE_ORIGINS = {
  RouteCart: ORIGIN_NAMES.CART,
  RouteCheckout: ORIGIN_NAMES.CHECKOUT,
  RouteOrderDetails: ORIGIN_NAMES.DELIVERY_DETAILS,
  RouteEditOrder: ORIGIN_NAMES.EDIT_DELIVERY,
  RouteTabHomescreen: ORIGIN_NAMES.HOMESCREEN,
  RouteTabMyMeds: ORIGIN_NAMES.MED_LIST,
  RouteMedDetails: ORIGIN_NAMES.MED_DETAILS,
  RouteTabAccount: ORIGIN_NAMES.PROFILE,
  RouteMessages: ORIGIN_NAMES.MESSAGES,
  // Transfer routes
  RouteTransferPrescription: ORIGIN_NAMES.TRANSFER_PRESCRIPTION,
};

const WEB_ORIGINS = {
  checkout: ORIGIN_NAMES.CHECKOUT,
  me: ORIGIN_NAMES.PROFILE,
  messages: ORIGIN_NAMES.MESSAGES,
  orders: ORIGIN_NAMES.DELIVERIES,
  prescriptions: ORIGIN_NAMES.MED_LIST,
  home: ORIGIN_NAMES.HOMESCREEN,
};

const ORIGINS_MAPPING = { ...MOBILE_ORIGINS, ...WEB_ORIGINS };

const getOriginName = (routeName: string): OriginName | null | undefined => {
  const path = isNative ? routeName : routeName.split('/')[1];
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
  const name = ORIGINS_MAPPING[path];

  if (name) {
    return name;
  }

  if (/\/orders\/[0-9]+/.test(routeName)) {
    return ORIGIN_NAMES.DELIVERY_DETAILS;
  }

  return null;
};

export default function getOrigin(): OriginName | null | undefined {
  const routeName = NavigationService.getCurrentRouteName();

  if (routeName) {
    return getOriginName(routeName);
  }

  return null;
}
