// @owners { team: patients-team }
import {
  type BACKGROUND_COLORS_VALUES,
  type BaseIconName,
  COLORS,
  type ColorVariant,
} from '@alto/design-library-tokens';
import { AltoIcon, Column, PresentationListItem, SmSpacing } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import React from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { CONFIRM } from '~shared/features/checkout/constants';
import getDefaultDeliveryWindow from '~shared/features/checkout/selectors/getDefaultDeliveryWindow';
import {
  getIsOrderPickupMethod,
  getOrderAddress,
  getOrderPickupAddress,
  getOrderPickupReadyTime,
} from '~shared/features/checkout/selectors/getOrder';
import { type HomeToSignConfirmation } from '~shared/features/checkout/types';
import {
  getDeliveryDateText,
  getDeliveryMethod,
  getFormattedAddress,
} from '~shared/features/delivery-info/helpers/getShipmentWithItemsRowProps';
import { type APIOrderMethod } from '~shared/features/delivery-info/types';
import { formatRelativeDateWithDow, formatWindowTime } from '~shared/helpers/date';
import { isControl, isExpensive } from '~shared/helpers/helper';
import { isCourier, isMail } from '~shared/helpers/order';
import { useSelectorShared } from '~shared/store';
import { type Address, type Prescription } from '~shared/types';
import { LocationRow } from '../../../info-rows';

type Props = {
  readonly date: string | null | undefined;
  readonly deliverAfter: string | null | undefined;
  readonly deliverBefore: string | null | undefined;
  readonly fullBleed?: boolean;
  readonly homeToSignConfirmation: HomeToSignConfirmation | null | undefined;
  readonly method: APIOrderMethod | null | undefined;
  readonly showMethod?: boolean;
  readonly showRefrigeratedInfo?: boolean;
  readonly status: string;
  readonly isAsapDelivery?: boolean;
};

type GetInstructionsProps = {
  address: Address | null | undefined;
  homeToSignConfirmation: HomeToSignConfirmation | null | undefined;
  prescriptions: Prescription[];
  method: APIOrderMethod | null | undefined;
  status: string;
  use3000Threshold: boolean;
  isOrderPickupMethod?: boolean;
};
export const getInstructions = ({
  address,
  homeToSignConfirmation,
  prescriptions,
  method,
  status,
  use3000Threshold,
  isOrderPickupMethod,
}: GetInstructionsProps): {
  iconName: BaseIconName;
  text: string;
  type?: ColorVariant;
  backgroundColor?: BACKGROUND_COLORS_VALUES;
} | null => {
  if (!address || ['delivered', 'failed'].includes(status)) {
    return {
      iconName: 'box',
      text: '',
    };
  }

  // for pickups, no need to show this instruction since 'Pick up order' is already shown right above
  if (method === 'pickup' || !!isOrderPickupMethod) {
    return null;
  }

  if (
    prescriptions.some((p) => isControl(p)) ||
    (isExpensive(prescriptions, use3000Threshold) && homeToSignConfirmation === CONFIRM)
  ) {
    return {
      iconName: 'signpaper',
      text: 'Someone needs to be present to sign for this delivery',
      type: 'grey',
      backgroundColor: COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST,
    };
  }

  if (isMail(method)) {
    return null;
  }

  if (!address.safe_place) {
    return {
      iconName: 'box',
      text: 'Someone needs to be present to receive this delivery',
      type: 'grey',
      backgroundColor: COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST,
    };
  }

  return {
    iconName: 'box',
    text: "We'll leave this package in your designated safe place",
    type: 'success',
  };
};

type DeliveryDateProps = {
  readonly date: string | null | undefined;
  readonly deliverBefore: string | null | undefined;
  readonly deliverAfter: string | null | undefined;
  readonly fullBleed: boolean;
  readonly method: APIOrderMethod | null | undefined;
  readonly isAsapDelivery?: boolean;
};

const DeliveryDate = ({ date, deliverAfter, deliverBefore, fullBleed, method, isAsapDelivery }: DeliveryDateProps) => {
  const orderPickupReadyTime = useSelectorShared(getOrderPickupReadyTime);
  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);

  const shouldShowPickupReadyTime = isOrderPickupMethod && !!orderPickupReadyTime;
  // Today, July 15th, ready by 9:05pm (estimated)
  const pickupReadyTime =
    !!orderPickupReadyTime && !!date
      ? `${formatRelativeDateWithDow(date)}, ready by ${formatWindowTime({ windowTime: orderPickupReadyTime, meridiem: 'aaa', forceReturnMinutes: true, useUTC: false })} (estimated)`
      : null;

  if (date) {
    return (
      <PresentationListItem
        LeftContent={<AltoIcon name="calendar" />}
        fullBleed={fullBleed}
        text={
          shouldShowPickupReadyTime
            ? pickupReadyTime
            : getDeliveryDateText({
                deliverAfter,
                deliverBefore,
                date,
                method,
                isAsapDelivery,
              })
        }
      />
    );
  }

  return (
    <PresentationListItem
      LeftContent={<AltoIcon name="calendar" />}
      fullBleed={fullBleed}
      text="--"
    />
  );
};

export const DeliveryLogistics = ({
  date,
  deliverAfter: deliverAfterTime,
  deliverBefore: deliverBeforeTime,
  fullBleed = false,
  homeToSignConfirmation,
  method,
  showMethod = true,
  showRefrigeratedInfo = true,
  status,
  isAsapDelivery,
}: Props) => {
  const address = useSelectorShared(getOrderAddress);
  const { prescriptions } = usePrescriptionsInCart();
  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);
  const pickupAddress = useSelectorShared(getOrderPickupAddress);
  const defaultWindow = useSelectorShared(getDefaultDeliveryWindow);
  const isCourierDeliveryMethod = isCourier(method) || isOrderPickupMethod;
  const { value: useNewThreshold } = Experimentation.useFeatureFlag('use_new_expensive_threshold');
  const deliveryMethod = getDeliveryMethod(method, showMethod, status, isOrderPickupMethod);
  const instructions = getInstructions({
    address,
    homeToSignConfirmation,
    prescriptions,
    method,
    status,
    use3000Threshold: useNewThreshold,
    isOrderPickupMethod,
  });
  const requiresRefrigeration = prescriptions.some((p) => p.is_refrigerated);
  const hasAncillaryItems = prescriptions.some((p) => p.has_ancillary_items);

  // Check for valid delivery window, grab default window for courier shipment, or set window to null
  let [deliverAfter, deliverBefore] = [deliverAfterTime, deliverBeforeTime];
  if (!deliverAfter && !deliverBefore) {
    if (isCourierDeliveryMethod) {
      [deliverAfter, deliverBefore] = [defaultWindow?.deliver_after, defaultWindow?.deliver_before];
    } else {
      [deliverAfter, deliverBefore] = [null, null];
    }
  }

  const deliveryAddress = isOrderPickupMethod ? pickupAddress : getFormattedAddress(address);

  return (
    <Column
      flexShrink={1}
      flexGrow={1}
    >
      <DeliveryDate
        date={date}
        deliverAfter={deliverAfter}
        deliverBefore={deliverBefore}
        fullBleed={fullBleed}
        method={method}
        isAsapDelivery={isAsapDelivery}
      />
      <LocationRow
        address={deliveryAddress}
        isPickup={isOrderPickupMethod}
        showMap={status !== 'delivered'}
      />
      {deliveryMethod.text ? (
        <PresentationListItem
          LeftContent={<AltoIcon name={deliveryMethod.iconName} />}
          fullBleed={fullBleed}
          text={deliveryMethod.text}
        />
      ) : null}
      {instructions?.text ? (
        <PresentationListItem
          LeftContent={<AltoIcon name={instructions.iconName} />}
          fullBleed={fullBleed}
          text={instructions.text}
          type={instructions.type}
          backgroundColor={instructions.backgroundColor}
        />
      ) : null}
      {showRefrigeratedInfo && requiresRefrigeration ? (
        <PresentationListItem
          LeftContent={<AltoIcon name="snowflake" />}
          fullBleed={fullBleed}
          text="Refrigeration required"
        />
      ) : null}
      {hasAncillaryItems ? (
        <PresentationListItem
          LeftContent={<AltoIcon name="syringe" />}
          fullBleed={fullBleed}
          text="Supplies included"
        />
      ) : null}
      <SmSpacing />
    </Column>
  );
};
