// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { AltoIcon, Column, ExpandableImage, LgPadding, PresentationListItem, XxsPadding } from '@alto/design-system';
import React from 'react';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { type APIOrderStatus } from '~shared/features/delivery-info/types';
import { getUserTagText } from '~shared/features/family-accounts/selectors/getUserTagText';
import { getShipmentTotal } from '~shared/features/pricing/selectors/getOrderTotal';
import {
  getAsapShipmentFee,
  getOnDemandShipmentFee,
} from '~shared/features/shipment-fees/selectors/getShipmentFeesFromShipment';
import { type ShipmentWithItems } from '~shared/features/shipments/types';
import getHasFamily from '~shared/features/users/selectors/getHasFamily';
import { isPickup } from '~shared/helpers/order';
import { useSelectorShared } from '~shared/store';
import {
  AncillaryRow,
  CalendarRow,
  DeliveryInstructionsRow,
  DeliveryMethodRow,
  LocationRow,
  PriceRow,
} from '../../info-rows';
import { OrderStatusTracker } from './OrderStatusTracker';

type Props = {
  readonly failedPaymentMethods?: string;
  readonly shipment: ShipmentWithItems;
  readonly showTracking?: boolean;
  readonly status?: APIOrderStatus;
};

export const OrderDetailsLogistics = ({ failedPaymentMethods, shipment, showTracking = false, status }: Props) => {
  const userTagText = useSelectorShared((state) => getUserTagText(state, { userIDs: [shipment.user_id] }));
  const userTag = useSelectorShared(getHasFamily) ? userTagText : '';
  const price = useSelectorShared(getShipmentTotal)?.value;
  const isOnDemand = !!(shipment && getOnDemandShipmentFee(shipment));
  const isAsapDelivery = !!getAsapShipmentFee(shipment);
  const requiresRefrigeration = shipment.items.some((item) => item.isRefrigerated);
  const hasAncillaryItems = shipment.items.some((item) => item.hasAncillaryItems);

  return (
    <Column
      flexShrink={1}
      flexGrow={1}
    >
      {userTag ? (
        <PresentationListItem
          LeftContent={
            <AltoIcon
              name="user"
              type="grey"
            />
          }
          text={userTag}
        />
      ) : null}
      {shipment.date ? (
        <CalendarRow
          startTime={shipment.deliver_after}
          endTime={shipment.deliver_before}
          date={status === 'delivered' ? shipment.delivered_at : shipment.date}
          method={shipment.method}
          status={status}
          isOnDemand={isOnDemand}
          isAsapDelivery={isAsapDelivery}
        />
      ) : null}
      <LocationRow
        address={shipment.address}
        isPickup={isPickup(shipment.method)}
        showMap={shipment.status !== 'delivered'}
      />
      <DeliveryMethodRow
        shipment={shipment}
        status={status}
      />
      <DeliveryInstructionsRow
        shipment={shipment}
        address={shipment.address}
        status={status}
      />
      <PriceRow price={price} />
      {failedPaymentMethods ? (
        <PresentationListItem
          backgroundColor={COLORS.BACKGROUND_COLORS.DANGER_LIGHTEST}
          LeftContent={
            <AltoIcon
              name="creditcard"
              type="grey"
            />
          }
          text={failedPaymentMethods}
        />
      ) : null}
      {requiresRefrigeration ? (
        <PresentationListItem
          LeftContent={
            <AltoIcon
              name="snowflake"
              type="grey"
            />
          }
          text="Refrigeration required"
        />
      ) : null}
      <AncillaryRow show={hasAncillaryItems} />
      {shipment.image_url && !isPickup(shipment.method) ? (
        <LgPadding
          topPadding={SPACING.STATIC.XS}
          bottomPadding={SPACING.STATIC.XXS}
          leftPadding={SPACING.STATIC.LG}
        >
          <ExpandableImage
            accessibilityLabel={IMG_ALT_TEXTS.deliveredDelivery}
            imageUrl={shipment.image_url}
            withThumbnailOverlay
          />
        </LgPadding>
      ) : null}
      {status ? (
        <LgPadding
          bottomPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
          topPadding={SPACING.STATIC.NONE}
          leftPadding={SPACING.STATIC.LG}
        >
          <OrderStatusTracker
            shipmentId={shipment?.id}
            deliveryMethod={shipment.method}
            deliveryStatus={status}
            showTracking={showTracking}
            shipmentTrackingLink={shipment?.tracking_link}
          />
        </LgPadding>
      ) : null}
      <XxsPadding />
    </Column>
  );
};
