// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  Body,
  Button,
  Card,
  Column,
  Illustration,
  LgPadding,
  MdSpacing,
  PressableAltoIcon,
  Row,
} from '@alto/design-system';
import React from 'react';
import { Linking } from 'react-native';
import styled from 'styled-components/native';
import { getPromoCardBackgroundColor } from './utils';

type Props = {
  title: string;
  cardType?: string;
  content?: string;
  ctaText?: string;
  imageURL?: string;
  onClose?: () => void;
  routeURL?: string;
};

const LeftContent = styled(Column)`
  max-width: 70%;
`;

export const PromoCard = ({ title, cardType, content, ctaText, imageURL, onClose, routeURL }: Props) => {
  const backgroundColor = getPromoCardBackgroundColor(cardType);

  const handlePress = () => {
    if (routeURL) {
      Linking.openURL(routeURL);
    }
  };

  return (
    <Card
      title={title}
      style={{ backgroundColor }}
      RightContent={
        onClose ? (
          <PressableAltoIcon
            name="close"
            onPress={onClose}
            accessibilityLabel={'close promo card'}
          />
        ) : undefined
      }
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Row
          spaceBetween
          rowStart
        >
          <LeftContent>
            {content ? <Body>{content}</Body> : null}
            {ctaText && routeURL ? (
              <>
                <MdSpacing />
                <Button
                  type="primary"
                  onPress={handlePress}
                  label={ctaText}
                  width="inline"
                  small
                />
              </>
            ) : null}
          </LeftContent>
          {imageURL ? (
            <Illustration
              source={imageURL}
              size="lg"
              accessibilityLabel="promo card"
            />
          ) : null}
        </Row>
      </LgPadding>
    </Card>
  );
};
