// @owners { team: patients-team }
import {
  NotificationsEndpoint,
  type NotificationsEndpointFetchAllResponse,
} from '@alto/scriptdash/alto/customer_support/patients/v1/notifications_endpoint';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useSelectorShared } from '~shared/store';
import { DEFAULT_PAGE_SIZE, notifications } from './queries';

const notificationsEndpoint = NotificationsEndpoint(apiEndpointHandler);

export const useFetchAllNotifications = () => {
  const currentUserID = useSelectorShared(getCurrentUserID);
  const apiParams = {
    patient_id: currentUserID || 0,
    pagination: {
      page_size: DEFAULT_PAGE_SIZE,
      page_token: '0',
    },
  };

  const { data, error, fetchNextPage, hasNextPage, isPending, isFetching, isSuccess } =
    useInfiniteQuery<NotificationsEndpointFetchAllResponse>({
      ...notifications.fetchNotifications(apiParams),
      queryFn: async ({ pageParam = 0 }) => {
        return await notificationsEndpoint.fetchAll({
          patient_id: apiParams.patient_id,
          pagination: { page_size: DEFAULT_PAGE_SIZE, page_token: pageParam as string },
        });
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage) => {
        return lastPage.metadata?.pagination?.next_page_token;
      },
      enabled: apiParams.patient_id > 0,
    });

  const messages = data?.pages.flatMap((page) => page.data);
  const unreadNotificationsCount = data?.pages[0].metadata?.unread_notifications_count || 0;
  return {
    data: messages,
    error,
    fetchNextPage,
    hasNextPage,
    isPending,
    isFetching,
    unreadNotificationsCount,
    isSuccess,
  };
};
