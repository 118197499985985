// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { AltoIconButton, Column, H2, Row, SmSpacing } from '@alto/design-system';
import React, { memo } from 'react';

type Props = {
  readonly itemQuantity: number;
  readonly onIncrease: () => void;
  readonly onDecrease: () => void;
};

export const EssentialsQuantityToggle = memo(function EssentialsQuantityToggleMemo({
  itemQuantity,
  onDecrease,
  onIncrease,
}: Props) {
  const quantityMax = 3;
  return (
    <Row centerVertically>
      {itemQuantity > 0 && (
        <AltoIconButton
          accessibilityLabel={`press to reduce quantity from ${itemQuantity} to ${itemQuantity - 1}`}
          type="secondary"
          iconName="minus"
          onPress={onDecrease}
        />
      )}
      <SmSpacing />
      <Column flexBasis="20px">
        <H2
          color={COLORS.TEXT_COLORS.SECONDARY}
          center
        >
          {itemQuantity}
        </H2>
      </Column>
      <SmSpacing />
      <Column flexBasis="36px">
        {itemQuantity < quantityMax && (
          <AltoIconButton
            accessibilityLabel={`press to increase quantity from ${itemQuantity} to ${itemQuantity + 1}`}
            type="secondary"
            iconName="plus"
            onPress={onIncrease}
          />
        )}
      </Column>
    </Row>
  );
});
