// @owners { team: patients-team }
import { Experimentation } from '@alto/experimentation';
import { type DateRestrictionReason } from '@alto/scriptdash/alto/scheduling/types/v1/date_restriction_reason';
import { addDays, isAfter, parse } from 'date-fns';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { getNextAvailableDateObjectForPrescription } from '~shared/features/next-available-date/selectors/getNextAvailableDateForPrescription';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { type ReduxStateShared, useSelectorShared } from '~shared/store';

export type BackorderedMedications = {
  medicationNames: string[];
  nextAvailableDateReasons: DateRestrictionReason[];
  nextAvailableDateThreshold: number;
};

const isBackOrderedMedicationName = (
  prescriptionName: string | undefined,
  medicationNames: string[] | undefined,
): boolean => {
  return (
    medicationNames?.some((medicationName) => prescriptionName?.toLowerCase().includes(medicationName.toLowerCase())) ??
    false
  );
};

const isWithinReason = (reason: string | undefined, reasons: string[]): boolean => {
  return reason ? reasons?.includes(reason) : false;
};

const getPrescriptionId = (_state: ReduxStateShared, prescriptionId: number | undefined) => prescriptionId;
const getFlagValue = (_state: ReduxStateShared, _: number | undefined, flagValue: BackorderedMedications) => flagValue;

const getIsBackOrderedMedication = createSelector(
  [getPrescriptionByID, getNextAvailableDateObjectForPrescription, getPrescriptionId, getFlagValue],
  (prescription, availableDate, _, flagValue) => {
    if (!prescription) return false;
    if (!availableDate?.earliest.date) return false;

    const isBackordered = isBackOrderedMedicationName(prescription?.medication_name, flagValue.medicationNames);
    if (!isBackordered) return false;

    const hasReason = isWithinReason(availableDate?.earliest?.reason, flagValue.nextAvailableDateReasons);
    if (!hasReason) return false;

    const threshold = addDays(new Date(), flagValue.nextAvailableDateThreshold || 0);
    return isAfter(parse(availableDate?.earliest.date, 'yyyy-MM-dd', new Date()), threshold);
  },
);

export const useBackorderedMedication = (prescriptionId: number | undefined): boolean => {
  const { value } = Experimentation.useFeatureFlag('backordered_medications', {});
  const isBackorderedMedication = useSelectorShared((state) =>
    getIsBackOrderedMedication(state, prescriptionId, value as BackorderedMedications),
  );
  if (isEmpty(value)) return false;
  return isBackorderedMedication;
};

export const useBackorderedMedications = (prescriptionIds: number[]): number[] => {
  const { value } = Experimentation.useFeatureFlag('backordered_medications', {});
  return useSelectorShared((state) =>
    prescriptionIds.filter((prescriptionId) =>
      getIsBackOrderedMedication(state, prescriptionId, value as BackorderedMedications),
    ),
  );
};
