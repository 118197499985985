import { SPACING } from '@alto/design-library-tokens';
import { Button } from '@alto/design-system';
import { useBackorderedMedications, useEssentialsCartThreshold } from '@alto/features';
import React from 'react';
import styled from 'styled-components';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { CartFooterOverline } from './CartFooterOverline';

const NextAvailableDateContainer = styled.div`
  padding-bottom: ${SPACING.STATIC.XS.px};
  text-align: center;
`;

type Props = {
  readonly handleCheckout: () => void;
  readonly fetchEnterCartLoading: boolean;
  readonly prescriptionIDs: number[];
};

export const CartFooter = ({ handleCheckout, fetchEnterCartLoading, prescriptionIDs }: Props) => {
  const { showEssentialsThreshold } = useEssentialsCartThreshold();
  const backorderedMedicationIds = useBackorderedMedications(prescriptionIDs);
  const hasBackorderedMedications = backorderedMedicationIds.length > 0;

  const { cartSize } = useCartData();
  return !cartSize ? null : (
    <>
      <NextAvailableDateContainer>
        <CartFooterOverline prescriptionIDs={prescriptionIDs} />
      </NextAvailableDateContainer>
      <Button
        disabled={showEssentialsThreshold || hasBackorderedMedications}
        loading={fetchEnterCartLoading}
        onPress={handleCheckout}
        label="Schedule order"
      />
    </>
  );
};
