import BikeCourierTransparentFlipped from '~shared/assets/images/bike_courier_transparent_flipped.png';
import CalendarAngled from '~shared/assets/images/calendar-angled.png';
import FedExLogo from '~shared/assets/images/fedex.png';
import ServiceFee from '~shared/assets/images/service-fee.png';
import USPSLogo from '~shared/assets/images/usps.png';
import { IMG_ALT_TEXTS } from '~shared/constants';
import {
  DELIVERY_METHOD_FEDEX_PRIORITY_OVERNIGHT,
  DELIVERY_METHOD_FEDEX_STANDARD_OVERNIGHT,
  DELIVERY_METHOD_USPS_FIRST,
  DELIVERY_METHOD_USPS_GROUND_ADVANTAGE,
  DELIVERY_METHOD_USPS_PRIORITY,
} from '~shared/features/checkout/constants';
import { type APIOrderMethod, type APIOrderStatus } from '~shared/features/delivery-info/types';
import { SHIPMENT_STATUS_MAP, type StatusMap } from '~shared/features/shipments/constants';

export const COURIER_DELIVERY_METHODS = ['courier', 'onfleet'];

export const MAIL_DELIVERY_METHODS = [
  DELIVERY_METHOD_FEDEX_PRIORITY_OVERNIGHT,
  DELIVERY_METHOD_FEDEX_STANDARD_OVERNIGHT,
  DELIVERY_METHOD_USPS_PRIORITY,
  DELIVERY_METHOD_USPS_FIRST,
  DELIVERY_METHOD_USPS_GROUND_ADVANTAGE,
  'mail',
];

export const isMail = (method: APIOrderMethod | null | undefined) => {
  return MAIL_DELIVERY_METHODS.includes(method ?? '');
};

export const isPickup = (method: APIOrderMethod | undefined | null) => {
  return method === 'pickup';
};

export const isCourier = (method: APIOrderMethod | undefined | null) => {
  return COURIER_DELIVERY_METHODS.includes(method ?? '');
};

export const isFedEx = (method: APIOrderMethod | null | undefined) => {
  return method?.includes('fedex') ?? false;
};

export const isUSPS = (method: APIOrderMethod | null | undefined) => {
  return method?.includes('usps') ?? false;
};

export const getMailLogo = (method: APIOrderMethod | null | undefined) => {
  if (isFedEx(method)) {
    return FedExLogo;
  }
  if (isUSPS(method)) {
    return USPSLogo;
  }
  return undefined;
};

export type orderHelperProps = {
  method: APIOrderMethod | undefined;
  status: APIOrderStatus | undefined | null;
  failedPaymentMethods?: string;
  shipmentStatusMap?: StatusMap;
};

export const getOrderStatusTitle = ({ method, status, failedPaymentMethods, shipmentStatusMap }: orderHelperProps) => {
  if (!status) {
    return '';
  }
  const statusMap = shipmentStatusMap || SHIPMENT_STATUS_MAP;
  if (failedPaymentMethods) {
    return 'Update your payment method';
  }
  if (isMail(method)) {
    return statusMap[status]?.mailInfoText || '';
  }
  if (isPickup(method)) {
    return status === 'shipped' ? 'Your order is ready' : statusMap[status]?.pickupInfoText || '';
  }
  // pickup and courier have same title in processing state
  return statusMap[status]?.infoText || '';
};

export const getIllustration = ({ method, status }: orderHelperProps) => {
  // show delivery logo throughout pickup lifecycle
  if (isPickup(method)) {
    return 'https://assets.prod.alto.com/delivery_methods/pickup.png';
  }
  // show mail logo throughout lifecycle
  if (isMail(method)) {
    return getMailLogo(method);
  }
  //show calendar for all processing stages
  if (status === 'processing' || status === 'awaiting_packing' || status === 'packaged') {
    return CalendarAngled;
  }
  //show bike if courier, and delivery if pickup for shipped stage
  if (status === 'shipped') {
    return BikeCourierTransparentFlipped;
  }
  //show service fee for delivered stage
  if (status === 'delivered') {
    return ServiceFee;
  }
};

// @ts-expect-error not all code paths return a value
export const getAccessibilityLabel = ({ method, status }: orderHelperProps) => {
  if (isPickup(method)) {
    return IMG_ALT_TEXTS.delivery;
  }
  if (status === 'processing' || status === 'awaiting_packing' || status === 'packaged') {
    return IMG_ALT_TEXTS.calendar;
  }

  if (status === 'shipped') {
    if (isFedEx(method)) {
      return 'Illustration of FedEx';
    }
    if (isUSPS(method)) {
      return 'Illustration of USPS';
    }
    return IMG_ALT_TEXTS.bikeCourier;
  }

  if (status === 'delivered') {
    return IMG_ALT_TEXTS.serviceFee;
  }
};
