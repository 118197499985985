// @owners { team: patients-team }
import { BulletedList, BulletedListItem, Description, LgPadding, ListDescription } from '@alto/design-system';
import { type AncillaryItemPrescription } from '@alto/scriptdash/alto/prescriptions/types/v3/ancillary_item_prescription';
import React from 'react';
import { SPACING } from '../../../../design-library-tokens';

type Props = {
  ancillaries: AncillaryItemPrescription[];
  isLoading?: boolean;
  showAncillaries?: boolean;
};

export const AncillaryListDescription = ({ ancillaries, isLoading = false, showAncillaries = false }: Props) => {
  if (isLoading) {
    return (
      <ListDescription
        key={'ancillaries-loading'}
        loading
      />
    );
  }

  if (!ancillaries.length) {
    return null;
  }

  return (
    <>
      <ListDescription iconName="syringe-small">Supplies included</ListDescription>
      {showAncillaries && (
        <LgPadding
          topPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.NONE}
        >
          <BulletedList>
            {ancillaries.map((ancillary) => {
              return (
                <BulletedListItem key={ancillary.ancillary_item_id}>
                  <Description>{ancillary.description}</Description>
                </BulletedListItem>
              );
            })}
          </BulletedList>
        </LgPadding>
      )}
    </>
  );
};
