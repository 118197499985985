// @owners { team: patients-team }
import { Column, ListDescription } from '@alto/design-system';
import { type MedDetailsPrescription } from '@alto/scriptdash/alto/medications/patients/v1/types';
import { type AncillaryItemPrescription } from '@alto/scriptdash/alto/prescriptions/types/v3/ancillary_item_prescription';
import { isBefore, parse } from 'date-fns';
import pluralize from 'pluralize';
import React from 'react';
import { getIsPrescriptionScheduleable } from '~shared/features/prescriptions/selectors/getScheduleableForPrescriptionID';
import { getCurrentUserIsEligibleForQuantityChange } from '~shared/features/users/selectors/getUserIsEligibleForQuantityChange';
import { useSelectorShared } from '~shared/store';
import { AncillaryListDescription } from '../../../ancillaries';
import { QuantityChangeLink } from '../../../quantity-change';

type Props = {
  readonly prescription: MedDetailsPrescription;
};

export const PrescriptionDescriptions = ({ prescription }: Props) => {
  const {
    expiration_date: expirationDateString,
    id: prescriptionID,
    last_filled,
    prescriber,
    quantity,
    remaining_refills,
    rx_number,
    signature,
    ancillaries,
  } = prescription;
  const expirationDate = parse(expirationDateString, 'MMMM dd, yyyy', new Date());
  const outOfRefillsOrExpired = remaining_refills === 0 || isBefore(expirationDate, new Date());
  const scheduleable = useSelectorShared((state) => getIsPrescriptionScheduleable(state, { prescriptionID }));
  const isEligibleForQuantityChange = useSelectorShared(getCurrentUserIsEligibleForQuantityChange);
  const showQuantityChangeLink = isEligibleForQuantityChange && scheduleable;

  return (
    <Column
      gap="XXS"
      flexShrink={1}
    >
      {[
        <ListDescription
          key={`${rx_number}-${quantity}`}
          iconName="pillbottle-small"
        >
          <>
            {quantity}
            {showQuantityChangeLink ? (
              <>
                &nbsp;•&nbsp;
                <QuantityChangeLink
                  prescriptionID={prescriptionID}
                  origin="med details"
                />
              </>
            ) : null}
          </>
        </ListDescription>,
        <ListDescription
          key={`${rx_number}-${remaining_refills}}`}
          iconName="sync-small"
          type={outOfRefillsOrExpired ? 'danger' : 'grey'}
        >
          {`${remaining_refills} ${pluralize('refill', remaining_refills)} before ${expirationDateString}`}
        </ListDescription>,
        <ListDescription
          key={`${rx_number}-${last_filled}`}
          iconName="calendar-small"
        >
          {last_filled}
        </ListDescription>,
        <ListDescription
          key={`${rx_number}-${prescriber}`}
          iconName="doctor-small"
        >
          {`Prescribed by ${prescriber}`}
        </ListDescription>,
        <ListDescription
          key={`${rx_number}-${signature}`}
          iconName="signpaper-small"
        >
          {signature}
        </ListDescription>,
        <ListDescription
          key={rx_number}
          iconName="rxchart-small"
        >
          {rx_number}
        </ListDescription>,
        <AncillaryListDescription
          key={`${rx_number}-ancillaries`}
          ancillaries={ancillaries as AncillaryItemPrescription[]}
          showAncillaries
        />,
      ]}
    </Column>
  );
};
