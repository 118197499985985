// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import { AltoIcon, BulletedList, BulletedListItem, Column, Description, Pressable, Row } from '@alto/design-system';
import { type AncillaryItemPrescription } from '@alto/scriptdash/alto/prescriptions/types/v3/ancillary_item_prescription';
import React, { useState } from 'react';
import styled from 'styled-components/native';

const AncillariesColumn = styled(Column)<{ collapsible?: boolean }>`
  border-radius: ${BORDERS.RADIUS.SM.px};
  padding: ${SPACING.STATIC.XS.px};
  padding-left: ${({ collapsible }) => (!collapsible ? SPACING.STATIC.XS.value : 0)};
`;

const Wrapper = ({
  collapsible,
  onPress,
  children,
}: {
  collapsible?: boolean;
  onPress: () => void;
  children: JSX.Element;
}) => {
  if (collapsible) {
    return <Pressable onPress={onPress}>{children}</Pressable>;
  }
  return <>{children}</>;
};

type Props = {
  readonly collapsible?: boolean;
  readonly ancillaries: AncillaryItemPrescription[];
};

export const Ancillaries = ({ collapsible, ancillaries }: Props) => {
  const [isListVisible, setIsListVisible] = useState(false);
  const showList = !collapsible || isListVisible;

  const handleToggleList = () => {
    if (collapsible) {
      setIsListVisible(!isListVisible);
    }
  };

  const mappedAncillaries = (
    <BulletedList>
      {ancillaries.map((ancillary) => {
        return (
          <BulletedListItem key={ancillary.id}>
            <Description>{ancillary.description}</Description>
          </BulletedListItem>
        );
      })}
    </BulletedList>
  );

  return (
    <AncillariesColumn backgroundColor={!collapsible ? COLORS.PALETTE.PRIMARY.LIGHTEST : 'none'}>
      <Wrapper
        collapsible={collapsible}
        onPress={handleToggleList}
      >
        <Row spaceBetween>
          <Description fontFamily="semibold">Supplies Included</Description>
          {collapsible ? (
            <AltoIcon
              name={isListVisible ? 'chevronup-small' : 'chevrondown-small'}
              type="grey"
            />
          ) : null}
        </Row>
      </Wrapper>
      {showList ? mappedAncillaries : null}
    </AncillariesColumn>
  );
};
