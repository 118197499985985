// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { AltoIcon, AltoSpinningLoader, Button, Caption, Description, Row, XsPadding } from '@alto/design-system';
import type { CartItem } from '@alto/orders_api/types/v1/cart_item';
import type { PriceOption, PriceType } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import React from 'react';
import { openModal } from '~shared/actions/modal';
import { getCashLessThanInsuranceSavings } from '~shared/features/checkout/helpers';
import type { PriceInfo } from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import { useDispatchShared } from '~shared/store';
import SavingsAppliedTag from '~web/features/checkout/components/SavingsAppliedTag';

type PricingSectionProps = {
  readonly cartItem: CartItem;
  readonly priceLoading: boolean;
  readonly pricingInfoForPrescription?:
    | PriceInfo
    | {
        price?: string | null | undefined;
        priceLabel?: string | null | undefined;
        paymentType?: PriceType | null | undefined;
      };

  readonly allowPriceOptionChange: boolean;
  readonly priceOptions: PriceOption[];
};

export const PricingSection = ({
  cartItem,
  priceLoading,
  pricingInfoForPrescription,
  allowPriceOptionChange,
  priceOptions,
}: PricingSectionProps) => {
  const dispatch = useDispatchShared();
  const { price, priceLabel, paymentType } = pricingInfoForPrescription || {};
  const cashSavingsText = getCashLessThanInsuranceSavings(priceOptions);

  if (priceLoading) {
    return (
      <Row rowCenter>
        <AltoSpinningLoader />
      </Row>
    );
  }

  return (
    <XsPadding
      leftPadding={SPACING.STATIC.NONE}
      rightPadding={SPACING.STATIC.NONE}
    >
      <Row
        spaceBetween
        wrap={false}
      >
        <Description>{priceLabel}</Description>
        <Description>{price || '-'}</Description>
      </Row>
      {cashSavingsText && paymentType === 'without_insurance' && (
        <Row>
          <Caption color={COLORS.TEXT_COLORS.SUCCESS}>{cashSavingsText}</Caption>
        </Row>
      )}
      <Row spaceBetween>
        <SavingsAppliedTag
          prescriptionID={cartItem.resource_id}
          priceType={paymentType}
        />
      </Row>
      {allowPriceOptionChange && (
        <Row gap="XS">
          <Button
            onPress={() => {
              dispatch(openModal('SELECT_PRICE_OPTION_MODAL', { prescriptionID: cartItem.resource_id }));
            }}
            RightContent={
              <AltoIcon
                name="edit-small"
                type="secondary"
              />
            }
            type="tertiary"
            variant="normal"
          >
            Change price option
          </Button>
        </Row>
      )}
    </XsPadding>
  );
};
