// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Description,
  H2,
  H3,
  LgPadding,
  LgSpacing,
  Link,
  MdSpacing,
  Row,
  XsSpacing,
  XxsSpacing,
} from '@alto/design-system';
import { type FulfillmentOption } from '@alto/scriptdash/alto/patient_app/checkout/v1/checkout_endpoint';
import React, { useContext } from 'react';
import { getIsOrderPickupMethod } from '~shared/features/checkout/selectors/getOrder';
import { formatWindowTime } from '~shared/helpers/date';
import { getPlatformOS } from '~shared/helpers/getPlatformOS';
import { useSelectorShared } from '~shared/store';
import { LocationRow } from '../../../info-rows';
import { getDisabledColor } from '../select-date-card/utils';
import { PharmacyHoursActionSheet } from './PharmacyHoursActionSheet';
import { formatStoreTime, getFeeLabels, getStoreHours } from './utils';

type Props = {
  pickupFulfillmentOptions: FulfillmentOption[];
  isFulfillmentOptionsLoading: boolean;
};

const isWeb = getPlatformOS() === 'web';

export const PickupOptionsSection = ({ pickupFulfillmentOptions, isFulfillmentOptionsLoading }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);
  const sameDaypickupFulfillmentOption = pickupFulfillmentOptions[0];
  const { label, pickup_data } = sameDaypickupFulfillmentOption;

  if (!isOrderPickupMethod || !pickup_data) {
    return null;
  }

  const { facility_address, ready_by_time } = pickup_data;
  const { feeAmountLabel } = getFeeLabels({
    fulfillmentOption: sameDaypickupFulfillmentOption,
    isDateOptionSelected: true,
  });
  const timeParsed = formatWindowTime({
    windowTime: ready_by_time,
    meridiem: 'aaa',
    forceReturnMinutes: true,
    useUTC: false,
  });
  const { storeOpenTime, storeCloseTime } = getStoreHours(pickup_data);
  const disabledColor = getDisabledColor(isFulfillmentOptionsLoading);

  const handleSeeAllHours = () => {
    setActiveActionSheet(<PharmacyHoursActionSheet pickupData={pickup_data} />);
  };

  return (
    <LgPadding topPadding={isWeb ? SPACING.STATIC.XS : SPACING.STATIC.LG}>
      <Row
        spaceBetween
        centerVertically
        wrap={false}
      >
        <H2>Pickup order</H2>
        {feeAmountLabel ? (
          <Description
            color={disabledColor}
            fontFamily="semibold"
          >
            {feeAmountLabel}
          </Description>
        ) : null}
      </Row>
      <MdSpacing />
      <H3>Estimated pickup time</H3>
      <XsSpacing />
      <Description>
        {label}, at {timeParsed}
      </Description>
      <LgSpacing />
      <H3>Pickup address</H3>
      <XsSpacing />
      <LocationRow
        address={facility_address}
        isPickup
        showMap
        copyOnly
      />
      <XsSpacing />
      <Description>{`Pickup hours today: ${formatStoreTime(storeOpenTime)} - ${formatStoreTime(storeCloseTime)}`}</Description>
      <XxsSpacing />
      <Link
        onPress={handleSeeAllHours}
        textSize="mini"
      >
        See all hours
      </Link>
    </LgPadding>
  );
};
