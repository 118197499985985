import { isBefore, isSameDay, parseISO, startOfDay } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { useCartNextAvailableDate } from '~shared/features/cart/hooks/useCartNextAvailableDate';
import { getNextAvailableDatesByPrescriptionID } from '~shared/features/next-available-date/selectors/getNextAvailableDatesByPrescriptionID';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import getOrigin from '~shared/lib/analytics/src/getOrigin';
import { Sentry } from '~shared/sentry';
import { useDispatchShared, useSelectorShared } from '~shared/store';

export const useCartViewed = () => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatchShared();
  const { cartItems } = useCartData();
  const {
    earliestAvailableDate: cartEarliestAvailableDate,
    earliestAvailableDateReason: cartEarliestAvailableDateReason,
    earliestAvailableDateInternalReason: cartEarliestAvailableDateInternalReason,
    lastRequestNextAvailableDateAttemptedAt,
  } = useCartNextAvailableDate();
  const origin = getOrigin();
  const cartItemResourceIds = useMemo(() => cartItems.map((item) => item.resource_id), [cartItems]);
  const nextAvailableDatesByPrescriptionID = useSelectorShared((state) => getNextAvailableDatesByPrescriptionID(state));
  const isNextAvailableDateValid =
    !!cartEarliestAvailableDate && !isBefore(parseISO(cartEarliestAvailableDate), startOfDay(new Date()));

  // Find the bottleneck prescriptions that are causing the the delay of the earliest available date
  const bottleneckPrescriptionsIds = useMemo(
    () =>
      cartItemResourceIds.reduce<number[]>((bottleneckPrescriptionsIds, prescriptionId) => {
        const ead = nextAvailableDatesByPrescriptionID[prescriptionId]?.earliest?.date;
        if (ead && isSameDay(parseISO(ead), parseISO(cartEarliestAvailableDate))) {
          bottleneckPrescriptionsIds.push(prescriptionId);
        }
        return bottleneckPrescriptionsIds;
      }, []),
    [cartItemResourceIds, cartEarliestAvailableDate, nextAvailableDatesByPrescriptionID],
  );

  // Determine if this cart has prescriptions with different next available dates
  const isMixedAvailabilityCart = useMemo(
    () =>
      cartItemResourceIds.some((prescriptionId) => {
        const ead = nextAvailableDatesByPrescriptionID[prescriptionId]?.earliest?.date;
        return ead && !isSameDay(parseISO(ead), parseISO(cartEarliestAvailableDate));
      }, []),
    [cartItemResourceIds, cartEarliestAvailableDate, nextAvailableDatesByPrescriptionID],
  );

  // recordCartViewedEvent to track prescription ids in the cart
  const recordCartViewedEvent = useCallback(() => {
    cartItemResourceIds.map((resourceId) => {
      trackEvent({
        event: EVENTS.CART_VIEWED,
        params: {
          origin,
          cart_prescription_ids: cartItemResourceIds,
        },
        additionalFields: {
          prescriptionId: resourceId,
        },
      });
    });
  }, [trackEvent, cartItemResourceIds.join('-'), origin]); // eslint-disable-line react-hooks/exhaustive-deps

  // recordCartViewedWithNextAvailableDateEvent to track NAD info when it's valid
  const recordCartViewedWithNextAvailableDateEvent = useCallback(() => {
    cartItemResourceIds.map((resourceId) => {
      const prescription_earliest_available_date_data = nextAvailableDatesByPrescriptionID[resourceId]?.earliest;
      if (!prescription_earliest_available_date_data?.internal_reason) return;

      trackEvent({
        event: EVENTS.CART_VIEWED_WITH_NEXT_AVAILABLE_DATE,
        params: {
          cart_delayed_availability_reason: cartEarliestAvailableDateReason || null,
          cart_delayed_availability_internal_reason: cartEarliestAvailableDateInternalReason || null,
          cart_next_available_date_from_now: cartEarliestAvailableDate || null,
          origin,
          prescription_delayed_availability_internal_reason:
            prescription_earliest_available_date_data?.internal_reason || null,
          prescription_next_available_date_from_now: prescription_earliest_available_date_data?.date || null,
          bottleneck_prescriptions_ids_causing_delay_of_this_prescription_id: bottleneckPrescriptionsIds,
          cart_prescription_ids: cartItemResourceIds,
          last_request_next_available_date_at: new Date(lastRequestNextAvailableDateAttemptedAt), //lastRequestNextAvailableDateAttemptedAt is Date.now() at last NAD request
          mixed_availability_cart: isMixedAvailabilityCart,
        },
        additionalFields: {
          prescriptionId: resourceId,
        },
      });
    });
  }, [
    trackEvent,
    cartItemResourceIds,
    cartEarliestAvailableDateReason,
    cartEarliestAvailableDateInternalReason,
    cartEarliestAvailableDate,
    origin,
    bottleneckPrescriptionsIds,
    nextAvailableDatesByPrescriptionID,
    lastRequestNextAvailableDateAttemptedAt,
    isMixedAvailabilityCart,
  ]);

  // Record the cart viewed event when cart items change
  useEffect(() => {
    recordCartViewedEvent();
  }, [cartItemResourceIds.join('-')]); // eslint-disable-line react-hooks/exhaustive-deps

  // Record the NAD info for the cart items when the cart is viewed
  useEffect(() => {
    const fetchNextAvailableDatesForCartItems = async () => {
      // eslint-disable-next-line import/no-deprecated
      await dispatch(fetchNextAvailableDates({ unbundledPrescriptionIDs: cartItemResourceIds }));
    };

    // eslint-disable-next-line promise/catch-or-return
    fetchNextAvailableDatesForCartItems()
      .catch(() => Sentry.captureMessage('Failed to fetch next available date at cart', 'info'))
      .finally(() => {
        if (isNextAvailableDateValid) {
          recordCartViewedWithNextAvailableDateEvent();
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Record the NAD updates when cart items changes are made while viewing the cart
  useEffect(() => {
    if (isNextAvailableDateValid) {
      recordCartViewedWithNextAvailableDateEvent();
    }
  }, [cartItemResourceIds.join('-'), cartEarliestAvailableDate]); // eslint-disable-line react-hooks/exhaustive-deps
};
