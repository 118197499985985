// @owners { team: patients-team }
import { ActionSheetContext } from '@alto/design-system';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React, { useCallback, useContext, useMemo } from 'react';
import { ORIGINS } from '~shared/features/essentials/add_ons_consts';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { useSelectorShared } from '~shared/store';
import { AddOnsContext } from '../../checkout';
import { EssentialsItemUpdateActionSheet } from '../components/EssentialsItemUpdateActionSheet';

type Props = {
  essential: AddOnOtc | null | undefined;
  shipmentID?: number | null;
};

export const useEditEssential = ({ essential, shipmentID }: Props) => {
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID }));
  const actionSheet = useContext(ActionSheetContext);
  const origin = useMemo(() => (shipmentID ? ORIGINS.EDIT_DELIVERY_DETAILS : ORIGINS.CART), [shipmentID]);
  const addOnsContext = useMemo(() => ({ shipmentID, origin }) as const, [origin, shipmentID]);

  const onChangeEssential = useCallback(() => {
    if (!essential) return;

    const analyticsProps = {
      slot: 0,
      sku: essential.sku,
      shipment_id: shipmentID,
      sku_type: essential.sku_type,
      otc_name: essential.name,
      category: null,
      query_string: null,
      facility_id: address?.dispensing_facility_id,
      origin,
    } as const;

    actionSheet.setActiveActionSheet(
      <AddOnsContext.Provider value={addOnsContext}>
        <EssentialsItemUpdateActionSheet
          essential={essential}
          analyticsProps={analyticsProps}
        />
      </AddOnsContext.Provider>,
    );
  }, [essential, shipmentID, address?.dispensing_facility_id, origin, actionSheet, addOnsContext]);

  return { onChangeEssential };
};
