import { SPACING } from '@alto/design-library-tokens';
import { Description, InlineAlert, LgPadding, XsSpacing } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { PaymentSummary, useEssentialsCartThreshold, useFetchAncillariesByPrescriptionIds } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { DeprecatedModalBody, Text } from '@alto/pocky';
import React from 'react';
import styled from 'styled-components';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { type OrderPricing } from '~shared/features/pricing/types';
import Item from './Item';
import { PrescriptionItemV2 } from '~web/features/checkout/components/PrescriptionItemV2';

const EmptyCartContainer = styled.div`
  padding: ${SPACING.STATIC.LG.px};
`;

type Props = {
  readonly includePaymentSummary?: boolean;
  readonly cartPricing: OrderPricing;
  readonly priceLoading: boolean;
  readonly fetchEnterCartLoading: boolean;
};

export const CartItemBody = ({ cartPricing, priceLoading, includePaymentSummary, fetchEnterCartLoading }: Props) => {
  const { showEssentialsThreshold, minimumAlertCopy, minimumThreshold } = useEssentialsCartThreshold();
  const showEssentialsMinWarning = showEssentialsThreshold && !priceLoading && !fetchEnterCartLoading;
  const { cartSize, cartItems } = useCartData();
  const { ancillaries } = useFetchAncillariesByPrescriptionIds({
    prescriptionIds: cartItems.map((item) => item.resource_id),
  });
  const { value: new_ancillary_ux_ui } = Experimentation.useFeatureFlag('ancillary_ux_ui');

  if (cartSize > 0)
    return (
      <DeprecatedModalBody>
        {showEssentialsMinWarning ? (
          <LgPadding bottomPadding={SPACING.STATIC.NONE}>
            <InlineAlert type="warning">
              <Description fontFamily="bold">${minimumThreshold} minimum subtotal</Description>
              <XsSpacing />
              <Description>{minimumAlertCopy}</Description>
            </InlineAlert>
          </LgPadding>
        ) : null}
        {cartItems.map((cartItem) =>
          new_ancillary_ux_ui ? (
            <PrescriptionItemV2
              key={cartItem.resource_id}
              cartItem={cartItem}
              ancillaries={ancillaries.filter((ancillary) => ancillary.prescription_id === cartItem.resource_id)}
            />
          ) : (
            <Item
              key={cartItem.resource_id}
              cartItem={cartItem}
            />
          ),
        )}
        {includePaymentSummary ? (
          <LgPadding topPadding={SPACING.STATIC.NONE}>
            <PaymentSummary
              pricing={cartPricing}
              origin="Cart"
            />
          </LgPadding>
        ) : null}
      </DeprecatedModalBody>
    );

  return (
    <DeprecatedModalBody>
      <EmptyCartContainer>
        <Text small>You don’t have anything in your cart.</Text>
      </EmptyCartContainer>
    </DeprecatedModalBody>
  );
};
